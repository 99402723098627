import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, Spacer } from '../../components/common';
import { connect } from 'react-redux';
import { IonButton } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { joinTable } from '../../store/actions';
import { Capacitor } from '@capacitor/core';
import QrCodeScanner from '../../components/qrCodeScanner';
import {
  BarcodeScanner,
  BarcodeFormat,
} from '@capacitor-mlkit/barcode-scanning';
import swirl from '../../assets/images/swirl.gif';
import { getConfig } from '../../appConfig';

const isWeb = () => Capacitor.getPlatform() === 'web';
const isAndroid = () => Capacitor.getPlatform() === 'android';
class QRScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTry: 0,
      progressPercentage: 0,
    };
  }

  componentDidMount() {
    localStorage.removeItem('currentTry');
    this.showNativeQrCodeScanner();
  }

  showNativeQrCodeScanner = async () => {
    if (!isWeb()) {
      try {
        const granted = await this.requestCameraPermissions();
        if (!granted) {
          forwardTo('/check-table', { disableAutoFocus: true });
          return null;
        }
        if (isAndroid() && !(await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable())) {
          await BarcodeScanner.installGoogleBarcodeScannerModule();
        }
        const { barcodes } = await BarcodeScanner.scan({
          formats: [BarcodeFormat.QrCode],
        });
        if (barcodes && barcodes.length > 0) {
          this.getTableData(barcodes[0].rawValue);
        }
      } catch (error) {
        console.log("Error reading QR code.", error);
        forwardTo('/check-table', { disableAutoFocus: true });
      }
    }
  };

  requestCameraPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  };

  getTableData = (value) => {
    const { dispatch } = this.props;
    const searchParams = new URLSearchParams(value);
    const token = searchParams.get('c');
    const location = searchParams.get('l');
    this.startProgress();
    dispatch(
      joinTable({
        location,
        token,
        errorCb: () => forwardTo('/check-table', { disableAutoFocus: true })
      })
    );
  };

  handleScanSuccess = (decodedText) => {
    this.getTableData(decodedText);
  };

  handleScanFailure = (error) => {
  };


  startProgress = () => {
    const refreshSeconds = getConfig().general.joinTableConfig.joinTableRefreshSeconds;
    const triesCounter = getConfig().general.joinTableConfig.joinTableCounter;
    const savedTry = localStorage.getItem('currentTry') || 0;
    this.setState({
      currentTry: parseInt(savedTry, 10),
      progressPercentage: Math.ceil((savedTry / triesCounter) * 100),
    });
    this.interval = setInterval(() => {
      this.setState(prevState => {
        const newTry = prevState.currentTry + 1;
        const newProgress = Math.ceil((newTry / triesCounter) * 100);
        localStorage.setItem('currentTry', newTry);
        if (newTry >= triesCounter) {
          clearInterval(this.interval);
          localStorage.removeItem('currentTry');
        }
        return {
          currentTry: newTry,
          progressPercentage: newProgress,
        };
      });
    }, refreshSeconds * 1000);
  };

  render() {
    const { __, loading } = this.props;
    return (
      <Layout 
        delay={2000} 
        color="transparent" 
        hideBack={true} 
        hideSecondToolbar={true}
        showHeader
        showFooter
        headerTitle={__('CheckTable')}
      >
        {loading ? (
          <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>
            <img style={{ flex: 1, objectFit: 'contain', display: 'block' }} src={swirl} alt="Loading" />
            <Spacer size={1} />
            <NormalText className="progress-bar-title">
              {__('Connecting to your table...')}
            </NormalText>
            <div className="progress-bar-wrapper">
              <div style={{ width: `${this.state.progressPercentage}%` }} className="progress-bar"></div>
            </div>
            <Spacer size={1} />
            <IonButton
              className="cancel-button"
              expand="block"
              strong={true}
              onClick={() => localStorage.setItem('cancelPool', true)}
            >
              {__('Cancel')}
            </IonButton>
          </div>
        ) : (
          <div className="absolute-content scrollable-y">
            {isWeb() ? (
              <QrCodeScanner
                onScanSuccess={this.handleScanSuccess}
                onScanFailure={this.handleScanFailure}
                __={__}
              />
            ) : null}
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    joinTableStarted: state.common.joinTableStarted,
  };
};

export default connect(mapStateToProps)(withTranslation(QRScanner));
