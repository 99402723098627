import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';

import { Capacitor } from '@capacitor/core';
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonIcon,
  IonAlert,
} from '@ionic/react';
import {
  getMycheckUrl,
  getMyTable,
  logout,
  setCommonModal,
  setDeliveryOption,
} from '../../store/actions';
import { getDefaultRoute, forwardTo, padNumber, isEmptyObject, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { NormalText, Title } from '../../components/common';
import packageJsonVersion from '../../../package.json';
import defaultImg from '../../assets/images/gray-avatar.png';
import './index.css';
import closeMenuIcon from '../../assets/images/icons/close-menu.svg';
import { Device } from '@capacitor/device';
import SmallDrawer from './smallDrawer';
import { getConfig } from '../../appConfig';

const openExternalLink = (url) => window.open(url, '_system', 'location=yes');

const NavItem = withRouter(({ history, item, hideIcon = true, handleLogout, className, __ }) => {
  const selected = history.location.pathname === item.path;
  return (
    <IonMenuToggle key={item.path} autoHide="false">
      <IonItem
        className={
          'nav-item' + (selected ? ' okx-nav-selected' : '') + (className ? ' ' + className : '')
        }
        onClick={() =>
          item.fn === 'logout'
            ? handleLogout()
            : item.isLink
              ? openExternalLink(item.path)
              : forwardTo(item.path, item.state)
        }
      >
        <div tabIndex="-1"></div>
        {hideIcon ? null : (
          <IonIcon className="nav-icon" slot="start" icon={item.icon ? item.icon : null} />
        )}
        <NormalText className="nav-label">{__(item.label)}</NormalText>
      </IonItem>
    </IonMenuToggle>
  );
});

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDeviceVersion: '',
      showPopup: false,
      menuVisible: true,
      logBoxOpen: false,
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      showMenu: true,
      emptyBasketAlertOpen:false
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleLogout() {
    this.props.dispatch(logout());
    const defaultRoute = getDefaultRoute(this.props.navConfig);
    forwardTo(defaultRoute.path);
  }

  handleModal(val) {
    this.setState({ showPopup: val });
  }

  async componentDidMount() {
    if (Basket.getDeliveryOption().id == 'gift-vouchers' && !this.props.giftVoucherData) {
      Basket.reset();
    }
    const info = await Device.getInfo();
    const appDeviceVersion = info.appVersion;
    this.setState({ appDeviceVersion: appDeviceVersion }, () => {
      this.checkVerison();
    });
  }

  addZeros = (arr = []) =>
    arr.map((i, index) => {
      // e.g. 1.23.8
      // skip first number (app version) (e.g. 1)
      // add zeros only to patch (e.g. 23) or minor (e.g. 8)
      if (index !== 0) {
        return padNumber(i, 3);
      }
      return i;
    });

  isAppVersionValid = (apiVersion = '', appVersion = '') => {
    let ret = true;
    if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
      const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10);
      const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10);
      ret = appVersionInt >= apiVersionInt;
    } else {
      // eslint-disable-next-line no-console
      console.error('Skip version checking.');
    }
    return ret;
  };

  checkVerison = () => {
    const { appDeviceVersion } = this.state;
    if (Capacitor.getPlatform() !== 'web') {
      if (
        !this.isAppVersionValid(this.props.appVersion, appDeviceVersion) &&
        appDeviceVersion !== ''
      ) {
        this.handleModal(true);
      }
    } else {
      // web version checking
      if (!this.isAppVersionValid(this.props.appVersion, packageJsonVersion.version)) {
        this.handleModal(true);
      }
    }
  };
  setDeliveryOption = (option) => {
    this.props.dispatch(setDeliveryOption(option));
    Basket.setDeliveryOption(option);
    Basket.setOrderType(option.id);
    forwardTo(option.route);
  };
  componentDidUpdate(prevProps) {
    if (this.props.profile.is_guest) {
      if (
        this.props.location.pathname !== '/order-completed' &&
        this.props.location.pathname !== '/checkout' &&
        this.props.location.pathname !== '/card-add' &&
        this.props.location.pathname !== '/create-account'
      ) {
        // Basket.reset();
        this.props.dispatch(logout());
      }
    }
    if (this.state.showMenu &&
      this.props.location.pathname == '/order' &&
      isWebConfig() &&
      this.state.menuVisible) {
      this.setState({ showMenu: false })
      this.toggleMenu()
    }
    if (!this.state.showMenu && this.props.location.pathname !== '/order') {
      this.setState({ showMenu: true })

    }
    if (this.props.appVersion !== prevProps.appVersion) {
      this.checkVerison();
    }

    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible }, () => {
      let drawer = this.state.menuVisible ? '--okx-drawer-max-width' : '--okx-small-drawer-max-width';
      document.documentElement.style.setProperty('--okx-drawer-width', `var(${drawer})`);
    });
  };

  toggleLogBox = () => {
    const { auth } = this.props;
    const { loggedIn } = auth;
    if (loggedIn) {
      this.setState({ logBoxOpen: !this.state.logBoxOpen });
    } else {
      forwardTo('/login');
    }
  };
  isItemActive = (id) => {
    let active = false;
    switch (id) {
      case 'charter-delivery':
        if (
          this.props.history.location.pathname == '/delivery' ||
          this.props.history.location.pathname == '/delivery-address-add' ||
          this.props.history.location.pathname == '/delivery-address-check' ||
          this.props.history.location.pathname == '/delivery-time'
        ) {
          active = true;
        }
        break;
      case 'table':
        if (this.props.history.location.pathname == '/order-to-table') {
          active = true;
        }
        break;
      case 'collection':
        if (this.props.history.location.pathname == '/click-and-collect') {
          active = true;
        }
    }
    return active;
  };
  forwardToOrder = () => {
    if (Basket.getItems().length > 0) {
      this.setState({ emptyBasketAlertOpen: true })
    } else {
      forwardTo('/click-and-collect')

    }
  }
  render() {
    const { auth, __, profile, navConfig } = this.props;
    const { showPopup, appDeviceVersion, menuVisible, logBoxOpen, profile_image_url } = this.state;
    const { loggedIn } = auth;
    const defaultRoute = getDefaultRoute(navConfig);

    const routes = navConfig.routes.filter((route) => !!route.path && !route.notInDrawer);
    const hasTable = !isEmptyObject(this.props.tableData);
    let hasOrdering = false
    if ((getConfig().orderTesting && getConfig().orderTesting.allowedUsers.includes(profile.ctcid)) || !getConfig().orderTesting) {
      hasOrdering = true
    }
    return (
      <IonMenu
        style={this.props.profile.is_guest ? { display: 'none' } : {}}
        className="drawer-menu"
        type="overlay"
        contentId="main"
        side={isWebConfig() ? 'start' : 'end'}
      >
        <>
          {menuVisible ?
            <IonContent className="sidebar-menu">
              {auth.loggedIn && (
                <Title className={`sidebar-user ${Capacitor.getPlatform() === 'ios' ? "sidebar-user-ios" : "" } `}>
                  {profile?.first_name} {profile?.last_name?.slice(0, 2)}{' '}
                </Title>
              )}
              <IonList lines="none">
                {!isWebConfig() &&
                  <>
                    {hasTable ? (
                      <div
                        onClick={() => {
                          this.props.dispatch(getMyTable(false));
                        }}
                      >
                        <IonMenuToggle autoHide="false">
                          <NormalText className="sidebar-link">{__('Your table')}</NormalText>
                        </IonMenuToggle>
                      </div>
                    ) : (
                      <div
                        onClick={() => forwardTo('/scan')}
                      >
                        <IonMenuToggle autoHide="false">
                          <NormalText className="sidebar-link">{__('Table check in')}</NormalText>
                        </IonMenuToggle>
                      </div>
                    )
                    }
                    <div onClick={() => forwardTo('/history', { tab: 'rewards' })}>
                      <IonMenuToggle autoHide="false">
                        <NormalText className="sidebar-link">{__('Your rewards')}</NormalText>
                      </IonMenuToggle>
                    </div>
                    <div onClick={() => forwardTo('/history', { tab: 'history' })}>
                      <IonMenuToggle autoHide="false">
                        <NormalText className="sidebar-link">{__('Your history')}</NormalText>
                      </IonMenuToggle>
                    </div>
                  </>
                }
                {hasOrdering ? <>
                  <div
                    onClick={() => this.forwardToOrder()}
                  >
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Order & collect')}</NormalText>
                    </IonMenuToggle>
                  </div>
                  {loggedIn &&
                    <div onClick={() => forwardTo('/previous-orders')}>

                      <IonMenuToggle autoHide="false">
                        <NormalText className="sidebar-link">{__('Previous orders')}</NormalText>
                      </IonMenuToggle>
                    </div>
                  }

                </> : <>
                  <div
                    onClick={() => {
                      this.props.dispatch(getMycheckUrl());
                    }}
                  >
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Order & Collect')}</NormalText>
                    </IonMenuToggle>
                  </div>
                </>}
              </IonList>
              <IonList lines="none" >
                {!isWebConfig() && <>

                  <div onClick={() => window.open('https://www.francomanca.co.uk/menu', '_blank')}>
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Our menu')}</NormalText>
                    </IonMenuToggle>
                  </div>
                  <div onClick={() => forwardTo('/add-stamps')}>
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Add stamps')}</NormalText>
                    </IonMenuToggle>
                  </div>
                  <div onClick={() => forwardTo('/refer-a-friend')}>
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Invite a friend')}</NormalText>
                    </IonMenuToggle>
                  </div>
                  <div onClick={() => forwardTo('/students')}>
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Students')}</NormalText>
                    </IonMenuToggle>
                  </div>
                  <div
                    onClick={() => {
                      this.props.dispatch(setCommonModal('isTutorialModalOpen', true));
                    }}
                  >
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('How to guide')}</NormalText>
                    </IonMenuToggle>
                  </div>
                </>}
                <div onClick={() => forwardTo('/faq')}>
                  <IonMenuToggle autoHide="false">
                    <NormalText className="sidebar-link">{__('FAQs')}</NormalText>
                  </IonMenuToggle>
                </div>
                <div onClick={() => forwardTo('/account')}>
                  <IonMenuToggle autoHide="false">
                    <NormalText className="sidebar-link">{__('Settings')}</NormalText>
                  </IonMenuToggle>
                </div>
                {loggedIn &&
                  <div onClick={() => forwardTo('/feedback')}>
                    <IonMenuToggle autoHide="false">
                      <NormalText className="sidebar-link">{__('Contact us')}</NormalText>
                    </IonMenuToggle>
                  </div>

                }

              </IonList>
              {isWebConfig() &&
                <>
                  {loggedIn ?
                    <NormalText onClick={() => this.handleLogout()} className="sidebar-auth sidebar-link">{__('Log out')}</NormalText>

                    :
                    <NormalText onClick={() => forwardTo('/login')} className="sidebar-auth sidebar-link">{__('Log in')}</NormalText>


                  }
                </>
              }
              {isWebConfig() ?
                <div className="close-menu pointer" onClick={() => this.toggleMenu()} >
                  <img src={closeMenuIcon} />
                </div>

                :
                <div className="close-menu">
                  <IonMenuToggle>
                    <img src={closeMenuIcon} />
                  </IonMenuToggle>
                </div>

              }


            </IonContent>
            : <SmallDrawer toggleMenu={this.toggleMenu} />}
        </>
        <IonAlert
          cssClass="two-button-alert"
          isOpen={this.state.emptyBasketAlertOpen}
          onDidDismiss={() => this.setState({ emptyBasketAlertOpen: false })}
          message={__('Do you want to empty your basket?')}
          buttons={[
            {
              text: __('Yes'),
              cssClass: 'two-button-secondary',
              handler: () => { Basket.reset(); forwardTo('/click-and-collect') },
            },
            {
              text: __('Cancel'),
              cssClass: 'two-button-gray',
              handler: () => forwardTo('/order'),
            },
          ]}
        />
        <IonAlert
          isOpen={showPopup}
          onDidDismiss={() => this.handleModal(false)}
          header={__('App version')}
          message={__('Your app is out of date. Please update.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleModal(false),
            },
          ]}
        />
      </IonMenu>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile } = state.profile;
  const { appVersion, navConfig } = state.common;
  const { tableData } = state.orders;
  return {
    auth,
    appVersion,
    profile,
    navConfig,
    tableData,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Drawer)));
