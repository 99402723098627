import React from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonButton, IonInput, IonItem } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import SplitedInput from '../../components/splitedInput';
import Modal from '../../components/modal';
import { joinTable, loading } from '../../store/actions';
import './index.css';
import { forwardTo } from '../../lib/utils';
import receipt from '../../assets/images/receipt.png';
import swirl from '../../assets/images/swirl.gif';
import { getConfig } from '../../appConfig';

class CheckTable extends React.Component {
  constructor(props) {
    super(props);
    this.unlisten = this.props.history.listen((location, action) => {
      if (action === 'POP') {
        this.handleBackButton();
      }
    });
    this.tokenInputRef = React.createRef();
    this.locationInputRef = React.createRef();

    this.state = {
      showTicket: false,
      code: '',
      showErrorAlert: false,
      currentTry: 0,
      progressPercentage: 0,
      tokenCode: '',
      locationCode: ''
    };
  }
  getTableData = (value) => {
    const searchParams = new URLSearchParams(value);
    const token = searchParams.get('c');
    const location = searchParams.get('l');
    this.props.dispatch(
      joinTable({
        location,
        token,
      }),
    );
  };

  codeHandler = (code) => {
    this.setState({ code: code });
  };
  submitHandler = () => {
    const { locationCode, tokenCode } = this.state;
    this.setState({ code: `${locationCode}${tokenCode}` }, () => {
      localStorage.removeItem('tableError');
      if (this.state.code.length === 8) {
        this.props.dispatch(
          joinTable({
            location: locationCode,
            token: tokenCode,
          }),
        );
        this.setState({ code: '', locationCode: '', tokenCode: '' });
        this.startProgress();

      } else {
        this.setState({ showErrorAlert: true });
      }
    })
  }
  handleLocationChange = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    this.setState({ locationCode: value }, () => {
      if (value.length === 4) {
        if (this.tokenInputRef.current) {
          this.tokenInputRef.current.setFocus();
        }
      }
    });

  }
  handleTokenChange = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

    if (this.state.tokenCode.length > 0 && value.length == 0) {
      if (this.locationInputRef.current) {
        this.locationInputRef.current.setFocus();
      }
    }
    this.setState({ tokenCode: value });
  }
  startProgress = () => {
    const refreshSeconds = getConfig().general.joinTableConfig.joinTableRefreshSeconds;
    const triesCounter = getConfig().general.joinTableConfig.joinTableCounter;
    const savedTry = localStorage.getItem('currentTry') || 0;
    this.setState({
      currentTry: parseInt(savedTry, 10),
      progressPercentage: Math.ceil((savedTry / triesCounter) * 100),
    });
    this.interval = setInterval(() => {
      this.setState(prevState => {
        const newTry = prevState.currentTry + 1;
        const newProgress = Math.ceil((newTry / triesCounter) * 100);
        localStorage.setItem('currentTry', newTry);
        if (newTry >= triesCounter) {
          clearInterval(this.interval);
          localStorage.removeItem('currentTry');
        }
        return {
          currentTry: newTry,
          progressPercentage: newProgress,
        };
      });
    }, refreshSeconds * 1000);
  };
  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.unlisten) {
      this.unlisten();
    }
  }
  componentDidMount() {
    localStorage.removeItem('currentTry')
    if(this.props.location?.state?.location && this.props.location?.state?.token){
      this.props.dispatch(
        joinTable({
          location: this.props.location?.state?.location,
          token: this.props.location?.state?.token,
        }),
      );
      this.startProgress();
    }
    if (!this?.props?.location?.state?.disableAutoFocus){
      setTimeout(() => {
        if (this.locationInputRef.current) {
          this.locationInputRef.current.setFocus();
        }
      }, 400);
    }
  }
  handleBackButton() {
   
    if(this.props.joinTableStarted){
      localStorage.setItem('cancelPool', true)
    }
  }
  render() {
    const { __ } = this.props;
    const { showErrorAlert } = this.state;
    return (
      <>
        <Layout
          color="transparent"
          showHeader
          showFooter
          hideSecondToolbar={true}
          headerTitle={__('CheckTable')}
        >
          {this.props.loading ? <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '80%' }}>

              <img style={{ flex: 1, objectFit: 'contain', display: 'block' }} src={swirl} />
              <Spacer size={1} />
              <NormalText className='progress-bar-title'>{__('Connecting to your table...')}</NormalText>
              <div className='progress-bar-wrapper'>
                <div style={{ width: `${this.state.progressPercentage}%` }} className='progress-bar'>
                </div>
              </div>
              <Spacer size={1} />
              <IonButton
                className="cancel-button"
                expand="block"
                strong={true}
                onClick={() => localStorage.setItem('cancelPool', true)}
              >
                {__('Cancel')}
              </IonButton>
            </div>
          </> : <div className='absolute-content scollable-y' style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <NormalText className="uppercase bold-text">
              {__('Oh no! Your qr code did not work....')}
            </NormalText>
            <Spacer size={1} />
            <NormalText className="uppercase bold-text">
              {__('Try entering the 8 digit code instead.')}
            </NormalText>
            <Spacer size={1} />
            <NormalText
              className="underlined"
              onClick={() => {
                this.setState({ showTicket: true });
              }}
            >
              {__('Where can I find my code?')}
            </NormalText>
            <Spacer size={1} />
            <div className='table-code-input-wrapper' style={{ width: '50%', marginBottom: '5px' }}>
              <IonItem lines='none'>
                <IonInput
                  ref={this.locationInputRef}
                  value={this.state.locationCode}
                  onInput={this.handleLocationChange}
                  maxlength="4"
                  type="tel"
                  placeholder='1234'
                  oninit
                />
              </IonItem>
            </div>
            <div className='table-code-input-wrapper' style={{ width: '50%' }}>
              <IonItem lines='none'>
                <IonInput
                  ref={this.tokenInputRef}
                  value={this.state.tokenCode}
                  onInput={this.handleTokenChange}
                  maxlength="4"
                  type="tel"
                  placeholder='1234'
                />
              </IonItem>
            </div>
            <IonButton
              className="student-button"
              expand="block"
              color="secondary"
              strong={true}
              onClick={this.submitHandler}
            >
              {__('Submit')}
            </IonButton>
            <IonButton
              className="cancel-button"
              expand="block"
              strong={true}
              onClick={() => forwardTo('/homepage')}
            >
              {__('Cancel')}
            </IonButton>
          </div>}
        </Layout>
        <IonAlert
          isOpen={showErrorAlert}
          onDidDismiss={() => this.setState({ showErrorAlert: false })}
          header={__('Oh no!')}
          message="Please enter an 8 digit code"
          cssClass="no-table-alert one-red-button-alert"
          color="secondary"
          buttons={[
            {
              text: 'OK',
              role: 'confirm',
              cssClass: 'secondary',
              //handler: () =>  forwardTo('/order-to-table')
            },
          ]}
        />

        <Modal
          cssClass="validate-modal"
          isOpen={this.state.showTicket}
          onDidDismiss={() => {
            this.setState({ showTicket: false });
          }}
        >
          <img src={receipt} />
        </Modal>
      </>
    );
  }
}

const stateToProps = (state) => {
  return {
    loading: state.common.loading,
    joinTableStarted:state.common.joinTableStarted
  };
};

export default connect(stateToProps)(withTranslation(CheckTable));
