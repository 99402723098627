import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IonApp, IonSplitPane, IonPage, IonAlert, IonContent, setupIonicReact } from '@ionic/react';
import { Router } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { getConfig } from './appConfig';
import ProtectedRoute from './components/protectedRoute';
import history from './history';
import { forwardTo, getDefaultRoute, getRouteClassName, isWeb, isWebConfig } from './lib/utils';
import { withTranslation } from '../src/lib/translate';
import {
  init,
  changeConnectionStatus,
  setMyLocation,
  setCommonModal,
  updateProfile,
} from './store/actions';
import ValidateModal from './components/validateModal';
import Drawer from './components/drawer';
import Toast from './components/toast';
import Loading from './components/spinner';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import mobiscroll from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import TutorialModal from './components/tutorialModal';
import NoTableModal from './components/noTableModal';
import Modal from './components/modal';
import { SET_COMMON_MODAL, SET_COMMON_PROP, SET_ORDERS_PROP } from './store/constants';
import NoClientFoundModal from './components/noClientFoundModal';
import { Network } from '@capacitor/network';
import { Geolocation } from '@capacitor/geolocation';
import { App as AppCap } from '@capacitor/app';
import Dashboard from './screens/dashboard';
import Basket from './lib/basket';
import { closeOutline, chevronDownCircleOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
addIcons({closeOutline, chevronDownCircleOutline});

const isAndroid = () => Capacitor.getPlatform() === 'android';

/* Theme */
require('./theme/' + (isWebConfig() ? 'web' : 'index') + '.css');

setupIonicReact({
  mode: 'md',
  innerHTMLTemplatesEnabled: true
});

AppCap.addListener('backButton', () => {
  if (
    history.location &&
    history.location.pathname &&
    getConfig().general.appExitRoutes.indexOf(history.location.pathname) !== -1
  ) {
    AppCap.exitApp();
  } else {
    if(this.props.joinTableStarted){
      localStorage.setItem('cancelPool', true)
    }
  }
});

AppCap.addListener('appUrlOpen', (event) => {
  let url = '';
  if(event.url.includes('.5loyalty.com')){
    url = event.url.split('.5loyalty.com').pop();
  }
  if (url) {
    history.push(url);
  }
});

const mapRoutes = (routes, extraProps = {}) => {
  return routes
    .filter((route) => !!route.path && !!route.component)
    .map((item) => {
      const { path } = item;
      const ActualRoute = item.protected ? ProtectedRoute : Route;
      return (
        <ActualRoute
          exact={item.exact}
          path={path}
          key={'nav-key-' + path}
          route={item}
          cmp={item.component}
          render={(props) =>
            item.render ? (
              item.render({ ...props, ...extraProps, route: item })
            ) : (
              <item.component {...props} {...extraProps} route={item} />
            )
          }
        />
      );
    });
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.defaultRoute = [];
    this.routeComponents = [];
    this.authRouteComponents = [];
    this.additionalRouteComponents = [];
    this.notInMenuRouteComponents = [];

    this.content = null;
  }

  async componentDidMount() {

    this.props.dispatch(init());

    Network.addListener('networkStatusChange', (conStatus) => {
      const status = conStatus.connected;
      this.props.dispatch(changeConnectionStatus(status));
    });
    this.getCurrentPosition();
    if(!isWeb() && isAndroid() && BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()){
      await BarcodeScanner.installGoogleBarcodeScannerModule();
    }
  }

  async getCurrentPosition() {
    const { myLocation, dispatch } = this.props;
    if (!myLocation.latitude && !myLocation.longitude) {
      // this.showModal(true)
      try {
        const coordinates = await Geolocation.getCurrentPosition({
          enableHighAccuracy: false,
        });
        const myLocation = {
          latitude: coordinates.coords.latitude,
          longitude: coordinates.coords.longitude,
        };
        dispatch(setMyLocation(myLocation));
      } catch (error) {
        if (
          JSON.stringify(error).indexOf('kCLErrorDomain') !== -1 &&
          JSON.stringify(error).indexOf('error 0') !== -1
        ) {
          this.getCurrentPosition();
        }
      }
    }
  }

  onRemoveValidateModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isValidationModalOpen', false));
    dispatch(updateProfile({ is_verification_pop_up_shown: true }, true));
  };

  onRemoveSnoozedSkusFromBasketModal = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isRemoveSnoozedSkusFromBasketModalOpen', false));
  };

  onVoucherAddedAlert = () => {
    const { dispatch } = this.props;
    dispatch({ type: SET_COMMON_PROP, key: 'voucherAddedAlertOpen', value: false });
  };
  onRedeemGiftVoucherModalClose = () => {
    const { dispatch } = this.props;
    dispatch(setCommonModal('isRedeemGiftVoucherModalOpen', false));
  };
  generateRoutes = () => {
    this.defaultRoute = getDefaultRoute(this.props.navConfig);
    this.routeComponents = mapRoutes(this.props.navConfig.routes);
    this.authRouteComponents = mapRoutes(this.props.navConfig.authRoutes);
    this.additionalRouteComponents = mapRoutes(this.props.navConfig.additionalRoutes);
    this.notInMenuRouteComponents = mapRoutes(this.props.navConfig.notInMenuRoutes);
  };
  clearPickUpBasket = () => {
		const { dispatch } = this.props;
		Basket.reset();
		dispatch(setCommonModal('removeBasketItemsModalOpen', { showAlert: false }));
		forwardTo('/homepage');
  };

  render() {
    const {
      __,
      isValidationModalOpen,
      initLoading,
      isRemoveSnoozedSkusFromBasketModalOpen,
      isRemoveSnoozedSkusFromBasketModalOpen_data,
      isRedeemGiftVoucherModalOpen,
      redeemGiftVoucherMessage,
      removeBasketItemsModalOpen,
    } = this.props;
    if (Capacitor.getPlatform() === 'web') {
      // mobi scroll style for desktop
      mobiscroll.settings = {
        theme: 'ios',
        themeVariant: 'light',
      };
      // wait for init saga to finish (reason: we dont have splash screen in web)
      if (!initLoading) {
        this.generateRoutes();
        this.content = null;
      } else {
        this.content = (
          <IonPage id="main">
          <IonContent>
              <Loading additionalLoadingCondition />
            </IonContent>
          </IonPage>
        );
      }
    } else {
      // mobile
      if (!initLoading) {
        this.generateRoutes();
        this.content = null;
      } else {
        this.content = (
          <IonPage id="main">
            <IonContent>
              <Loading additionalLoadingCondition />
            </IonContent>
          </IonPage>
        );
      }
    }
    const routeClassName =  history.location.pathname.includes('native-') ? getRouteClassName(history.location.pathname, this.props.navConfig):'';
    return (
      <IonApp className={`${isWebConfig() ? 'web' : ''} ${routeClassName}` }>
        <Router history={history}>
          {this.content ? (
            this.content
          ) : (
            <IonSplitPane {...(isWebConfig() ? { when: '(min-width:768px)' } : { when: '(min-width:2000px)' })} contentId="main">
              <Drawer />
              <IonPage id="main">
                <Switch>
                  {this.routeComponents}
                  {this.authRouteComponents}
                  {this.additionalRouteComponents}
                  {this.notInMenuRouteComponents}
                  <Route path="/:token">
                    <Dashboard {...this.props} history={history} />
                  </Route>
                  {this.defaultRoute ? (
                    <Route exact path="/" render={() => <Redirect to={this.defaultRoute.path} />} />
                  ) : null}
                </Switch>
                <Toast />
                <ValidateModal />
                <TutorialModal />
                <NoTableModal />
                <NoClientFoundModal />
                <IonAlert
                  isOpen={isRedeemGiftVoucherModalOpen}
                  onDidDismiss={this.onRedeemGiftVoucherModalClose}
                  header={__('Success')}
                  message={__(redeemGiftVoucherMessage)}
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRedeemGiftVoucherModalClose,
                    },
                  ]}
                /> 
                <IonAlert
                  isOpen={isValidationModalOpen}
                  onDidDismiss={this.onRemoveValidateModal}
                  header={__('Success')}
                  message={__('The app is now unlocked to redeem your loyalty')}
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRemoveValidateModal,
                    },
                  ]}
                />

                <IonAlert
                  isOpen={isRemoveSnoozedSkusFromBasketModalOpen}
                  onDidDismiss={this.onRemoveSnoozedSkusFromBasketModal}
                  header={__('Warning')}
                  message={
                    __(isRemoveSnoozedSkusFromBasketModalOpen_data.fixedText || '') +
                    '<br/>' +
                    (isRemoveSnoozedSkusFromBasketModalOpen_data.items || [])
                      .map((i, index) => index + 1 + '. ' + i.productName)
                      .join('<br/>')
                  }
                  buttons={[
                    {
                      text: __('Close'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onRemoveSnoozedSkusFromBasketModal,
                    },
                  ]}
                />
                <Modal
                  cssClass="faq-modal"
                  onDidDismiss={() =>
                    this.props.dispatch({
                      type: SET_COMMON_MODAL,
                      modal: 'faqModalOpen',
                      value: false,
                    })
                  }
                  isOpen={this.props.faqModalOpen}
                >
                  <div className="inner-padding">
                    <div dangerouslySetInnerHTML={{ __html: this.props.faq }}></div>
                  </div>
                </Modal>
                <IonAlert
                  isOpen={this.props.voucherAddedAlertOpen}
                  onDidDismiss={() => this.onVoucherAddedAlert}
                  message={__('Reward added successfully')}
                  buttons={[
                    {
                      text: __('OK'),
                      role: 'cancel',
                      cssClass: 'secondary',
                      handler: this.onVoucherAddedAlert,
                    },
                  ]}
                />
                <IonAlert
                  cssClass="two-button-alert"
                  isOpen={this.props.changeCollectionTimeModalOpen}
                  onDidDismiss={() => { this.props.dispatch({ type: SET_ORDERS_PROP, key: 'changeCollectionTimeModalTime', value: '' }); this.props.dispatch({ type: SET_ORDERS_PROP, key: 'changeCollectionTimeModalOpen', value: false }) }}
                  message={`${__(`The time slot you requested is no longer available and has been updated to`)} ${this.props.changeCollectionTimeModalTime}`}
                  buttons={[
                    {
                      text: __('OK'),
                      cssClass: 'two-button-secondary',
                      handler: () =>{ Basket.setCollectionTimeFromModal(this.props.changeCollectionTimeModalTime);}
                    },
                    {
                      text: __('Pick another time'),
                      cssClass: 'two-button-gray',
                      handler: () => forwardTo('/click-and-collect', { selectedRestaurant: true }),
                    },
                  ]}
                />
                <IonAlert
									isOpen={removeBasketItemsModalOpen?.showAlert}
									onDidDismiss={this.clearPickUpBasket}
									header={
                    getConfig().general.basketTimeHasPassedTitle 
                    ? __(getConfig().general.basketTimeHasPassedTitle )
                    : __('Basket')}
                  className={'one-red-button-alert'}
									message={
										getConfig().general.basketTimeHasPassedMessage 
											? __(getConfig().general.basketTimeHasPassedMessage)
											: __('Your collection time has now passed, please select diferent collection time and place your order.')
									}
									buttons={[
										{
											text: __('OK'),
											handler: () => {
                        this.clearPickUpBasket();
                      },
                    },
                  ]}
                />
              </IonPage>
            </IonSplitPane>
          )}
        </Router>
      </IonApp>
    );
  }
}

const stateToProps = (store) => {
  const { auth, profile } = store.profile;
  const { changeCollectionTimeModalOpen, changeCollectionTimeModalTime } = store.orders
  const {
    myLocation,
    initLoading,
    isRemoveSnoozedSkusFromBasketModalOpen,
    isRemoveSnoozedSkusFromBasketModalOpen_data,
    isRedeemGiftVoucherModalOpen,
    redeemGiftVoucherMessage,
    navConfig,
    faq,
    faqModalOpen,
    voucherAddedAlertOpen,
    removeBasketItemsModalOpen,
    joinTableStarted
  } = store.common;
  return {
    auth,
    myLocation,
    profile,
    initLoading,
    isRemoveSnoozedSkusFromBasketModalOpen,
    isRemoveSnoozedSkusFromBasketModalOpen_data: isRemoveSnoozedSkusFromBasketModalOpen_data || {},
    isRedeemGiftVoucherModalOpen,
    redeemGiftVoucherMessage,
    navConfig,
    faq,
    faqModalOpen,
    voucherAddedAlertOpen,
    changeCollectionTimeModalOpen,
    changeCollectionTimeModalTime,
    removeBasketItemsModalOpen,
    joinTableStarted
  };
};

export default connect(stateToProps)(withTranslation(App));
