import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonGrid, IonRow, IonCol, IonIcon, IonInput } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject, forwardTo, goBack, getDefaultRoute } from '../../lib/utils';
import Loading from '../../components/spinner';
import { NormalText, Title } from '../../components/common';
import OrderList from './orderList';
import './index.css';
import Basket from '../../lib/basket';
import { loading, setScrollTop } from '../../store/actions';
import TileOrderList from './tileOrderList';
import { getConfig } from '../../appConfig';
import { chevronBack, close, search } from 'ionicons/icons';
import ContentHeader from '../../components/contentHeader';

const MENU_DISPLAY_TYPE = {
  SCROLL_MENU_DISPLAY_TYPE: 1,
  TILE_MENU_DISPLAY_TYPE: 2,
  DOUBLE_TILE_MENU_DISPLAY_TYPE: 3,
};

const selectedMenuType = getConfig().menuDisplayType;
const { getDeliveryOption, getDeliveryAddress, getPickUpPoint } = Basket;

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.searchBoxInput = React.createRef();
    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      menuRefs: null,
      categoriesPositionTop: [],
      selectedMenu: [],
      searchItemsValue: '',
      originalMenu: null,
      searchVisible: false
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }

  setIkentooMenu = (menu) => {
    this.setState(
      {
        ikentooMenu:
          Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
        originalMenu:
          Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
        if (!isEmptyObject(this.state.ikentooMenu)) this.props.dispatch(loading(false));
      },
    );
  };
  componentDidMount() {
    if(getConfig().flags.redirectToStartNewOrder && isEmptyObject(Basket.getRestaurant())){
      forwardTo('/click-and-collect')
      return
    }
    if (
      !isEmptyObject(this.props.defaultMenu) ||
      !isEmptyObject(this.props.ikentooMenu || !isEmptyObject(this.state.ikentooMenu))
    ) {
      this.props.dispatch(loading(false));
    } else {
      this.props.dispatch(loading(true));
    }
    this.setIkentooMenu();
    this.setState({
      selectedMenuType: getConfig().menuDisplayType,
    });
    if (this.props.location.state) {
      this.setState({ selectedMenu: this.props.location.state.selectedMenu, searchItemsValue: '' });
    }
  }

  shouldComponentUpdate(nextProps) {
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }

  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
        let name = `${value.name}_${index}`;
        acc[name] = React.createRef();
        return acc;
      }, {})
      : {};
  };

  scrollTo = (name) => this.state.menuRefs[name].current.scrollIntoView();

  selectCategoryOnScroll = (e) => {
    const scrollTop = Math.abs(e.target.scrollTop);
    const heading = document.querySelector('.order-header-content-wrapper');
    if (heading && scrollTop > 10) {
      heading.style.display = 'none';
    } else {
      heading.style.display = 'block';
    }
    e.preventDefault();
    const positionTop = scrollTop + document.querySelector('.order-categories').clientHeight;
    const { menuRefs } = this.state;
    const positions = Object.keys(menuRefs).map((key) => {
      return menuRefs[key].current.offsetTop;
    });
    let selectCategory = 0;
    positions.forEach((item, i) => {
      if (item <= positionTop) {
        selectCategory = i;
      }
    });
    if (document) {
      const catDiv = document.querySelector('.order-categories-inner');
      if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
        catDiv.childNodes[selectCategory].scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        });
      }
    }
    this.props.dispatch(setScrollTop(scrollTop));
    this.setState({ selectedCategory: selectCategory, scrollTop: scrollTop });
  };

  backHandler = () => {
    let menuDisplayType = this.state.selectedMenuType;
    const selectedMenus = this.state.selectedMenu || [];
    if (this.state.searchItemsValue != '') {
      this.setState({ selectedMenu: [], searchItemsValue: '' });
    } else {
      if (menuDisplayType !== 1 && selectedMenus.length > 0) {
        const items = this.state.selectedMenu;
        items.pop();
        this.setState({ selectedMenu: items });
      } else {
        forwardTo('/click-and-collect');
      }
    }
  };

  selectMenu = (index) => {
    const selectedItem = index;
    const currentCategories = [...(this.state.selectedMenu || [])];
    currentCategories.push(selectedItem);
    this.setState({ selectedMenu: currentCategories });
  };

  parseCategories = (data) => {
    const { menuDisplayType, ikentooMenu } = data;
    const selectedMenu = data.selectedMenu || [];

    let categories;
    if (menuDisplayType !== 1) {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu : [];
      if (selectedMenu.length > 0) {
        categories = categories.menuEntryGroups[selectedMenu[0]];
        for (let i = 1; i < selectedMenu.length; i++) {
          categories = categories.menuEntry[selectedMenu[i]];
        }
      }
    } else {
      categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    }
    return categories;
  };
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  }
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (
          item.productName.toLowerCase().includes(`${this.state.searchItemsValue.toLowerCase()}`)
        ) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  }
  filterMenuItems = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      if (getConfig().menuDisplayType !== 1) {
        if (this.state.selectedMenuType != 1 && this.state.searchItemsValue !== '') {
          this.setState({ selectedMenuType: 1 }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else if (this.state.selectedMenuType == 1 && this.state.searchItemsValue == '') {
          this.setState({ selectedMenuType: getConfig().menuDisplayType }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else {
          const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
          const filtered = this.filterMenu(menu);
          this.setState({ ikentooMenu: filtered }, () => {
            let menuRefs = this.menuRefs(this.state.ikentooMenu);
            this.setState({ menuRefs });
          });
        }
      } else {
        const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
        const filtered = this.filterMenu(menu);
        this.setState({ ikentooMenu: filtered }, () => {
          let menuRefs = this.menuRefs(this.state.ikentooMenu);
          this.setState({ menuRefs });
        });
      }
    });
  };
  render() {
    const { __, scrollTop } = this.props;
    const { selectedCategory, ikentooMenu, selectedMenu } = this.state;
    let menuDisplayType = this.state.selectedMenuType;
    let categories = this.parseCategories({ selectedMenu, ikentooMenu, menuDisplayType });
    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    const headerTitle =
      Basket.getOrderType() && (this.props.isPassedOrder || Basket.getPassedOrder())
        ? Basket.getOrderType()
        : 'Menu';
    return (
      <Loading>
        <Layout
          showHeader={true}
          showFooter={true}
          scrollY={false}
          headerTitle={__(headerTitle)}
          headerWithTitle={true}
          hideSecondToolbar={true}
          noPadding
          color="transparent"
          backHandler={this.backHandler}
          showBack
        >
          <div className='page-secondary-header' style={{ flex: 'unset' }}>
            <IonIcon onClick={() => this.backHandler()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>

            <Title className="secondary-font dark-color">{__('Order & collect')}</Title>
            <IonIcon onClick={() => this.setState({ searchVisible: !this.state.searchVisible })} className={`${this.state.searchVisible ? 'page-secondary-header-search-active' : ''} page-secondary-header-search`} color="gray" icon={search}></IonIcon>
            {this.state.searchVisible &&
              <div className="search-box">
                <IonIcon icon={search}></IonIcon>
                <IonInput
                  ref={this.searchBoxInput}
                  placeholder={__('Search for a product')}
                  value={this.state.searchItemsValue}
                  onIonChange={this.filterMenuItems}
                />

              </div>
            }
          </div>

          {!isDefined(ikentooMenu) ? null : menuDisplayType == 1 ? (
            <div className="segment-holder">

              {this.props.isPassedOrder || Basket.getPassedOrder() ? (
                <div className="order-header-content-wrapper">

                  <>
                    <ContentHeader
                      __={__}
                      deliveryOption={getDeliveryOption() || {}}
                      orderType={labelTitle}
                      deliveryAddress={getDeliveryAddress()}
                      pickUpPoint={getPickUpPoint()}
                      tableNumberHandler={this.tableNumberHandler}
                    />

                  </>
                </div>

              ) : null}
              {/* {this.state.searchVisible &&
                <div className="search-box">
                <IonIcon color="secondary" icon={search}></IonIcon>
                <IonInput
                  ref={this.searchBoxInput}
                  placeholder={__('Search products')}
                  value={this.state.searchItemsValue}
                  onIonChange={this.filterMenuItems}
                />
                <IonIcon
                  onClick={() => this.setState({ searchItemsValue: '', selectedMenu: [] })}
                  className="reset-icon"
                  color="secondary"
                  icon={close}
                ></IonIcon>
              </div>
              } */}

              {/* <div className="order-categories">
                {categories.length > 0 ? (
                  <div className="order-categories-inner">
                    {categories.map((category, index) => {
                      return (
                        <IonButton
                          key={index}
                          size="small"
                          fill="clear"
                          className={
                            selectedCategory === index
                              ? 'category-button active'
                              : 'category-button'
                          }
                          onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                        >
                          {__(category?.name.toLowerCase())}
                        </IonButton>
                      );
                    })}
                  </div>
                ) : (
                  <NormalText className="no-items-message">{__('No items found')}</NormalText>
                )}
              </div> */}
              <div className="order-content">
                <OrderList
                  scrollTopPosition={scrollTop}
                  selectCategoryOnScroll={this.selectCategoryOnScroll}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                  searchBoxInput={this.searchBoxInput}
                />

                {Basket.itemsCountAll() > 0 ? (
                  <div className="view-order-button">
                    <IonButton
                      color="secondary"
                      onClick={() => forwardTo('/order-summary')}
                      expand="block"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ textAlign: 'start' }} class='secondary-font'>{__('View order')}</IonCol>
                          {/* <IonCol>
                            {Basket.itemsCountAll()}&nbsp;
                            {Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
                          </IonCol> */}
                          <IonCol style={{ textAlign: 'end' }} class='secondary-font'>{Basket._getTotal()}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="segment-holder grid-menus">
              {this.props.isPassedOrder || Basket.getPassedOrder() ? (
                <div className="order-header-content-wrapper">

                  <ContentHeader
                    __={__}
                    deliveryOption={getDeliveryOption() || {}}
                    orderType={labelTitle}
                    deliveryAddress={getDeliveryAddress()}
                    pickUpPoint={getPickUpPoint()}
                    tableNumberHandler={this.tableNumberHandler}
                  />
                </div>

              ) : null}

              <div className="order-categories">
                <TileOrderList
                  selectMenu={(index) => {
                    this.selectMenu(index);
                  }}
                  category={categories}
                  selectedMenu={this.state.selectedMenu||[]}
                ></TileOrderList>
                <div className='order-page-text' style={{ marginBottom: Basket.itemsCountAll() > 0 ? '80px' : '30px' }}>
                  <NormalText >{__(getConfig().general.orderPageText)}</NormalText>

                </div>
              </div>
              <div className="order-content">
                {Basket.itemsCountAll() > 0 ? (
                  <div className="view-order-button">
                    <IonButton
                      onClick={() => forwardTo('/order-summary')}
                      expand="block"
                      color="secondary"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol style={{ textAlign: 'start' }} class='secondary-font'>{__('View order')}</IonCol>
                          {/* <IonCol class='secondary-font'>
                            {Basket.itemsCountAll()}&nbsp;
                            {Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
                          </IonCol> */}
                          <IonCol style={{ textAlign: 'end' }} class='secondary-font'>{Basket._getTotal()}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </Layout>
      </Loading>
    );
  }
}
const stateToProps = (state) => {
  const { auth } = state.common;
  const {
    restaurants,
    ikentooMenu,
    defaultMenu,
    restaurantsUpdated,
    isPassedOrder,
  } = state.restaurants;
  const { scrollTop, deliveryOption } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    restaurantsUpdated,
    scrollTop,
    deliveryOption,
    isPassedOrder,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
