import React from 'react';
import { IonAlert, IonButton, IonInput, IonItem, IonModal } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText, Subtitle } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import TermsModal from '../../components/termsModal';
import SplitedInput from '../../components/splitedInput';
import './index.css';
import { joinTable, redeemFeedbackCode } from '../../store/actions';
import Modal from '../../components/modal';
import { SET_COMMON_MODAL, SET_COMMON_PROP } from '../../store/constants';
import { connect } from 'react-redux';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import receipt from '../../assets/images/receipt-stamp-code.png';

class AddStamps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTicket: false,
      showTerms: false,
      showAlert: false,
      code: '',
    };
    this.codeInputRef = React.createRef();
  }
componentDidMount(){
  setTimeout(() => {
    if (this.codeInputRef.current) {
      this.codeInputRef.current.setFocus();
    }
  }, 400);
}
  codeHandler = (code) => {
    this.setState({ code: code });
  };
  checkCode() {
    const code = this.state.code.replace('-','')
    if (/^\d{8}$/.test(code)) {
      this.setState({ showAlert: true });
    } else {
      this.props.dispatch(redeemFeedbackCode(code));
    }
  }
  handleCodeChange = (e) => {
    let value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if (value.length > 4) {
      value = `${value.slice(0, 4)}-${value.slice(4, 8)}`;
    }
    if (value.length > 9) {
      value = value.slice(0, 9);
    }
    this.setState({ code: value });
  };
  render() {
    const { __ } = this.props;
    const { showAlert, showTerms, showTicket, code } = this.state;
    return (
      <Loading transparent>
        <Layout
          showHeader
          showFooter
          color="transparent"
          headerTitle={__('Refer a Friend')}
          hideBack={true}
          hideSecondToolbar={true}
        >
          <Subtitle className="small-subtitle">{__('GRAB YOUR RECEIPT!')}</Subtitle>
          <Spacer size={1} />
          <NormalText className="small-subtitle uppercase bold-text">
            {__('Type in the 8 digit code from the bottom of your receipt and redeem your stamp!')}
          </NormalText>
          <Spacer size={1} />
          <NormalText className="small-subtitle uppercase bold-text">
            {__('Your stamp is automatically added if you used order + collect online')}
          </NormalText>
          <Spacer size={1} />
          <NormalText
            className="underlined link-color"
            onClick={() => this.setState({ showTerms: true })}
          >
            {__('T&CS')}
          </NormalText>
          <Spacer size={1} />
          <NormalText
            className="underlined"
            onClick={() => {
              this.setState({ showTicket: true });
            }}
          >
            {__('Where can I find my code?')}
          </NormalText>
          <Spacer size={1} />
          <div className='table-code-input-wrapper'>
            <IonItem lines='none'>
              <IonInput
              ref={this.codeInputRef}
                value={code}
                onInput={this.handleCodeChange}
                maxlength="9"
                type="text"
                placeholder='ABCD-ABCD'
              />
            </IonItem>
          </div>
          <Spacer size={1} />
          <IonButton
            disabled={code?.length < 8}
            expand="block"
            color="secondary"
            strong={true}
            onClick={() => this.checkCode()}
          >
            {__('Submit')}
          </IonButton>
        </Layout>
        <TermsModal isOpen={showTerms} onDidDismiss={() => this.setState({ showTerms: false })} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => this.setState({ showAlert: false })}
          header={__('Check in code')}
          message="You have entered a check in code, we will check you in"
          cssClass="one-button-alert check-in-alert"
          color="secondary"
          buttons={[
            {
              cssClass:'secondary',
              text: 'OK, check me in',
              role: 'confirm',
              handler: () => {
                forwardTo('/check-table',{
                  location: code.slice(0,4), token: code.slice(5, 9)
                })
      
              },
            },
          ]}
        ></IonAlert>

        <IonAlert
          isOpen={!isEmptyObject(this.props.stampsModalContent)}
          onDidDismiss={() => {
            this.props.dispatch({ type: SET_COMMON_PROP, key: 'stampsModalContent', value: {} });
          }}
          header={__(this.props.stampsModalContent?.popup_header_text)}
          message={__(this.props.stampsModalContent?.popup_body_text)}
          cssClass="two-button-alert"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: this.props.stampsModalContent?.ok_button_label,
            },
            {
              cssClass: 'two-button-gray',
              text: this.props.stampsModalContent?.cancel_button_label,
            },
          ]}
        />
        <Modal
          cssClass="validate-modal"
          isOpen={showTicket}
          onDidDismiss={() => {
            this.setState({ showTicket: false });
          }}
        >
          <img src={receipt} />
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    stampsModalContent: state.common.stampsModalContent,
  };
};
export default connect(stateToProps)(withTranslation(AddStamps));
