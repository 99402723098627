import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonButtons,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { Title, NormalText, SmallText, Spacer, StrongText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { forwardTo, checkForDeliveryOption, isEmptyObject, isWebConfig } from '../../lib/utils';
import {
  setDeliveryAddress,
  postCodeCheck,
  setPostCodeData,
  getNearestLocation,
  setCommonModal,
  removeDeliveryAddress,
  setOrdersProp,
} from '../../store/actions';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { getConfig } from '../../appConfig';
import trashIcon from '../../assets/images/trash.svg';

import './index.css';

class Delivery extends React.Component {
  state = {
    selectedAddress: null,
    deliveryZoneOption: '',
    deliveryZone: [],
    restaurant: {},
    removeDeliveryAddress: null,
  };

  componentDidMount() {
    const { deliveryOption } = this.props;
    let delivery_id = deliveryOption && deliveryOption.id ? deliveryOption.id : '';
    let dataForDeliveryOption = null;

    if (!delivery_id) {
      const order_type = Basket.getOrderType();
      const deliveryConfig = getConfig().delivery;
      Object.keys(deliveryConfig).map((key) => {
        if (deliveryConfig[key].id === order_type.toLowerCase()) {
          dataForDeliveryOption = deliveryConfig[key];
        }
      });
      if (dataForDeliveryOption) {
        delivery_id = dataForDeliveryOption.id;
      } else {
        forwardTo('/delivery-options');
      }
    }
    checkForDeliveryOption(Basket.getDeliveryOption(), '/delivery');
    this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
    const { address_list, charter_delivery_address_list } = this.props.profile;
    const newAddressList = delivery_id
      ? delivery_id === 'charter-delivery'
        ? charter_delivery_address_list
        : address_list
      : address_list;
    if (newAddressList && newAddressList.length > 0) {
      const defaultAddress = newAddressList.find((da) => da.default);
      this.setState({ selectedAddress: defaultAddress || newAddressList[0] });
    } else {
      if (this.props.history.action === 'POP') {
        forwardTo('/dashboard');
      } else {
        const list = this.props.profile.charter_delivery_address_list || [];
        if (list.length === 0) {
          forwardTo('/delivery-address-check');
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { address_list, charter_delivery_address_list } = this.props.profile;
    const addresses =
      Basket.getOrderType() === 'charter-delivery' ? charter_delivery_address_list : address_list;
    if (addresses?.length === 0) {
      forwardTo('/delivery-address-check');
    }
    const { restaurants } = this.props;
    checkForDeliveryOption(Basket.getDeliveryOption(), '/delivery');
    if (
      this.props.checkedCodeData.length > 0 &&
      prevProps.checkedCodeData !== this.props.checkedCodeData
    ) {
      const copiedRestaurants = JSON.parse(JSON.stringify(restaurants));
      const filteredRestaurants = copiedRestaurants.filter(
        (restaurant) => !restaurant.can_charter_delivery_order,
      );
      let deliveryZone = JSON.parse(JSON.stringify(this.props.checkedCodeData)).filter((el) =>
        filteredRestaurants.every((fd) => fd.id != el.restaurant_id),
      );
      this.setState({ selectedRestaurantPage: true, deliveryZone });

      // if (this.props.checkedCodeData.length === 1) {
      //   let deliveryZone = this.props.checkedCodeData;
      //   const selectedRestaurantId = this.props.checkedCodeData[0].restaurant_id;
      //   this.setState(
      //     {
      //       checking: false,
      //       postalCodeValid: true,
      //       restaurant: this.props.checkedCodeData[0],
      //       deliveryZone,
      //     },
      //     () => {
      //       this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true));
      //       Basket.setRestaurant(
      //         restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
      //       );
      //       Basket.setServicePercentage(0)
      //       Basket.setDeliveryAddress(this.state.selectedAddress);
      //       Basket.setDeliveryPrice(deliveryZone[0].delivery_zone.price);
      //       Basket.setMinOrder(deliveryZone[0].delivery_zone.min_order);
      //       forwardTo('/delivery-address-add');
      //     },
      //   );
      // } else if (this.props.checkedCodeData.length > 1) {
      //   let deliveryZone = this.props.checkedCodeData;
      //   this.setState({ checking: false, postalCodeValid: true, deliveryZone }, () => {
      //     this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true));
      //   });
      // }
    } else if (prevState.checking && this.props.checkedCodeData.length === 0) {
      this.setState({ checking: false, postalCodeValid: false });
    }
  }
  confirmAddress = () => {
    const { selectedAddress } = this.state;
    const restaurants = this.props.restaurants;
    const selectedRestaurantId = this.props.checkedCodeData[this.state.deliveryZoneOption]
      .restaurant_id;
    if (selectedAddress) {
      this.props.dispatch(setDeliveryAddress(selectedAddress));
      this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
      Basket.setRestaurant(
        restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
      );
      Basket.setServicePercentage(0);
      const deliveryCharge = this.state.deliveryZone[this.state.deliveryZoneOption].delivery_zone
        .price;
      Basket.setDeliveryPrice(deliveryCharge);
      Basket.setDeliveryAddress(selectedAddress);
      forwardTo('/delivery-address-add', {
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
    }
  };

  check = (value, type) => {
    Basket.setDeliveryAddress(this.state.selectedAddress);
    let charterDelivery = false;
    if (Basket.getOrderType() === 'charter-delivery') {
      charterDelivery = true;
    }
    if (type === 'postcode') {
      this.props.dispatch(postCodeCheck(value, charterDelivery));
      this.setState({ initial: false, checking: true });
    } else if (type === 'distance' || type === 'polygon') {
      this.props.dispatch(getNearestLocation(value, true));
      this.setState({ initial: false, checking: true, restaurant: {} });
    } else {
      this.setState({ initial: true }, () => {
        this.props.dispatch(setPostCodeData({ data: [] }));
      });
    }
    this.setState({ initial: false, checking: true });
  };

  changeDeliveryZone = (event) =>
    this.setState({ deliveryZoneOption: event.detail.value, error: '' });

  checkAvailableSlotsForToday = (restaurant) => {
    let today = moment().format('dddd');
    const day = {
      Monday: 'w1',
      Tuesday: 'w2',
      Wednesday: 'w3',
      Thursday: 'w4',
      Friday: 'w5',
      Saturday: 'w6',
      Sunday: 'w0',
    };
    let selectedRestaurant = this.props.restaurants.find((r) => r.id === restaurant.restaurant_id);
    let deliveryTimes = selectedRestaurant.charter_delivery_times_json;
    let flag = false;
    if (!isEmptyObject(deliveryTimes)) {
      deliveryTimes[0].availability.forEach((dt) => {
        if (dt.d === day[today]) {
          let addedTime = null;
          addedTime = moment().add(restaurant.charter_delivery_order_slot_lead_time, 'minutes');
          if (addedTime.isBefore(moment(dt.end, 'hh:mm'))) {
            flag = true;
          }
        }
      });
    }
    if (flag) {
      const openingTimes = selectedRestaurant.json_opening_time_info.filter(
        (el) => el.day == today,
      )[0];
      if (openingTimes) {
        return openingTimes.time.split('-')[1].trim();
      } else {
        return false;
      }
    } else {
      return flag;
    }
  };

  handleRemoveDeliveryAddress = (data, flag) => {
    this.setState({ removeDeliveryAddress: data });
    this.props.dispatch(setOrdersProp('removeAddressModal', flag));
  };

  removeDeliveryAddress = () => {
    this.props.dispatch(
      removeDeliveryAddress(this.state.removeDeliveryAddress, Basket.getOrderType()),
    );
    this.setState({ removeDeliveryAddress: null });
  };

  render() {
    const { __, profile, isChooseDeliveryModalOpen, deliveryRangeType } = this.props;
    const { selectedAddress, deliveryZoneOption, deliveryZone } = this.state;
    const { address_list, charter_delivery_address_list } = profile;
    const addresses =
      Basket.getOrderType() === 'charter-delivery' ? charter_delivery_address_list : address_list;

    const hasAddress = addresses && addresses.length > 0;
    const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : '';
    return (
      <Loading transparent>
        <Layout hideSecondToolbar={true} headerTitle={__('Delivery Order')} headerWithTitle={true}>
          <div className="flex-row-wrapper absolute-content">
            {!this.state.selectedRestaurantPage ? (
              <>
                <div className="scrollable-y">
                  <Title className="web-only">{__('Delivery Order')}</Title>
                  {hasAddress ? (
                    <>
                      <NormalText>{__('Select your delivery address')}</NormalText>
                      <Spacer />
                      <IonList lines="none" className="box-wrapper">
                        <IonRadioGroup
                          value={selectedAddress}
                          onIonInput={(e) => this.setState({ selectedAddress: e.detail.value })}
                        >
                          {addresses.map((da, index) => {
                            return (
                              <IonItem
                                className="default-padding"
                                key={'delivery-address-' + index}
                                lines="none"
                              >
                                <div tabIndex="-1"></div>
                                <IonRadio
                                  color={isWebConfig() ? 'secondary' : 'white'}
                                  slot="start"
                                  value={da}
                                  className="delivery-radio-button"
                                />
                                <IonButtons slot="end">
                                  <IonButton
                                    onClick={() => this.handleRemoveDeliveryAddress(index, true)}
                                    className="link"
                                  >
                                    <IonIcon color="secondary" icon={trashIcon} />
                                  </IonButton>
                                </IonButtons>
                                <IonLabel>
                                  <SmallText>
                                    {da.addressLine1}, {da.place}
                                  </SmallText>
                                  <br />
                                  <NormalText color="black">{da.postalCode}</NormalText>
                                </IonLabel>
                              </IonItem>
                            );
                          })}
                        </IonRadioGroup>
                      </IonList>
                    </>
                  ) : null}
                  <IonButton
                    fill="clear"
                    className="link underlined"
                    color="secondary"
                    onClick={() => {
                      forwardTo('/delivery-address-check');
                    }}
                  >
                    {__((hasAddress ? 'Or add another' : 'Add') + ' delivery address')}
                  </IonButton>
                  <div className="flex-min">
                    <IonButton
                      disabled={!selectedAddress}
                      expand="block"
                      color="secondary"
                      onClick={() =>
                        this.check(
                          selectedAddress
                            ? `${selectedAddress.addressLine1}, ${selectedAddress.addressLine2}, ${selectedAddress.place}, ${selectedAddress.postalCode}`
                            : '',
                          deliveryRangeType,
                        )
                      }
                    >
                      {__('Continue')}
                    </IonButton>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <Title className="web-only">{__('Delivery Order')}</Title>
                <NormalText>{__('Select a restaurant')}</NormalText>
                <Spacer />
                <IonList>
                  <IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
                    {deliveryZone
                      .sort((a, b) => {
                        return a.delivery_zone.price - b.delivery_zone.price;
                      })
                      .map((restaurant, i) => {
                        const { restaurant_name, restaurant_address, delivery_zone } = restaurant;

                        const price = restaurant.delivery_zone.price;
                        let availableSlosts = this.checkAvailableSlotsForToday(restaurant);
                        return (
                          <IonItem
                            key={i}
                            lines="none"
                            className="box-wrapper"
                            style={{ marginBottom: '10px' }}
                          >
                            <div tabIndex="-1"></div>
                            <IonRadio
                              color={isWebConfig() ? 'secondary' : 'white'}
                              slot="start"
                              value={i}
                              disabled={!availableSlosts}
                            />
                            <IonLabel
                              style={{ margin: '0' }}
                              className="ion-text-wrap"
                              color="dark"
                            >
                              <StrongText>{restaurant_name}</StrongText>
                              <SmallText className="block">{restaurant_address}</SmallText>
                              <div className="delivery-additional-restaurant-information">
                                <div>
                                  {price > 0 ? (
                                    <SmallText>
                                      {__('Delivery charge')}:{Basket.formatPrice(price)}
                                    </SmallText>
                                  ) : (
                                    <SmallText>{__('Free Delivery')}</SmallText>
                                  )}
                                  <SmallText className="block">
                                    {__('Minimun Order')}:
                                    {Basket.formatPrice(delivery_zone.min_order)}
                                  </SmallText>
                                </div>
                                <SmallText className="restaurant-opening-time">
                                  {' '}
                                  {!availableSlosts
                                    ? __('Service Unavailable')
                                    : ` ${__('Open until')} ${availableSlosts}`}
                                </SmallText>
                              </div>
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                  </IonRadioGroup>
                </IonList>
                <Spacer size={1} />
                <IonButton
                  disabled={deliveryZoneOption === ''}
                  expand="block"
                  color="secondary"
                  onClick={() => {
                    this.confirmAddress();
                  }}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            )}
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <h3>{__('Choose delivery')}</h3>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() =>
                  this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))
                }
              >
                <IonIcon
                  role="img"
                  className="md hydrated"
                  icon="close-outline" 
                ></IonIcon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList>
                  <IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
                    {deliveryZone
                      .sort((a, b) => {
                        return a.delivery_zone.price - b.delivery_zone.price;
                      })
                      .map((restaurant, i) => {
                        const { restaurant_name } = restaurant;
                        const price = restaurant.delivery_zone.price;
                        let availableSlosts = this.checkAvailableSlotsForToday(restaurant);
                        return (
                          <IonItem key={i} lines="none">
                            <div tabIndex="-1"></div>
                            <IonRadio
                              color={isWebConfig() ? 'secondary' : 'white'}
                              slot="start"
                              value={i}
                            />
                            <IonLabel className="ion-text-wrap" color="dark">
                              {price > 0
                                ? `${restaurant_name} delivery price - ${
                                    Basket.getCurrency().label
                                  } ${price}`
                                : `${restaurant_name}  -  ${__('Free Delivery')}`}
                              <br />
                              {!availableSlosts ? __('No available slots for today') : null}
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                <IonButton
                  disabled={deliveryZoneOption === ''}
                  expand="block"
                  color="secondary"
                  onClick={() => {
                    this.confirmAddress();
                  }}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.removeAddressModal === true}
          onDidDismiss={() => this.handleRemoveDeliveryAddress(null, false)}
          header={__('Confirm')}
          message={__('Do you you want to remove this delivery address?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.removeDeliveryAddress(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { deliveryOption, checkedCodeData, removeAddressModal } = state.orders;
  const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common;
  const { restaurants } = state.restaurants;
  return {
    profile,
    deliveryOption,
    deliveryRangeType,
    isChooseDeliveryModalOpen,
    checkedCodeData,
    restaurants,
    removeAddressModal,
  };
};

export default connect(stateToProps)(withTranslation(Delivery));
