import React from 'react';
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonCheckbox,
} from '@ionic/react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import { forwardTo, getDefaultRoute, goBack } from '../../lib/utils';
import {
  registerRequest,
  setModal,
  setSysLocale,
  setRegisterForm,
} from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import { FieldError, Spacer, NormalText } from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import moment from 'moment';
import { Device } from '@capacitor/device';

const { DatePicker } = Mobiscroll;
const DATE_FORMAT = 'DD/MM/YYYY';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      showAdditionalDetails: false,
      registerFirstBlock: true,
      registerSecondBlock: false,
      registerErrorAlertOpen: false,
      userIsTooYoung: false,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfigFirstBlock = {
      email: { type: 'email', required: true },
      first_name: { type: 'text', required: true },
      last_name: { type: 'text', required: true },
      mobile: { type: 'tel', required: true },
    };
    this.formConfigSecondBlock = {
      password: { type: 'password', required: true },
      repeat_password: { type: 'repeat_password', required: true },
      birthday: { type: 'text', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  validateForm() {
    const { registerFormData } = this.props;
    const formConfigFull = { ...this.formConfigFirstBlock, ...this.formConfigSecondBlock };
    const minAgeRequired = (getConfig().general && getConfig().general.minAgeRequired) || 14;
    const yearsDiff = moment().diff(moment(registerFormData.birthday, DATE_FORMAT), 'years');
    if (parseInt(yearsDiff) < parseInt(minAgeRequired)) {
      this.setState({ userIsTooYoung: true });
    } else {
      let formErrors = validateForm(formConfigFull, registerFormData);
      this.setState({ formErrors });
      if (Object.keys(formErrors).length === 0) {
        this.handleRegister();
      } else {
        this.setState({ registerErrorAlertOpen: true });
      }
    }
  }

  handleRegister() {
    const { protectedReferrer } = this.props;
    this.props.dispatch(registerRequest());
  }
  componentDidMount() {
    const { loggedIn } = this.props.auth;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path, {
        fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
      });
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');
  backHandler = () => {
    if (!this.state.showTime) {
      goBack();
    } else {
      this.setState({ showTime: false });
    }
  };
  validateFirstBlockForm = () => {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfigFirstBlock, registerFormData);
    this.setState({ formErrors });

    if (Object.keys(formErrors).length === 0) {
      this.setState({ registerSecondBlock: true, registerFirstBlock: false });
    } else {
      this.setState({ registerErrorAlertOpen: true });
    }
  };
  formatErrorMessage = (message) => {
    let errorMessage = '';
    switch (message) {
      case 'Required field':
        errorMessage = 'All fields are required';
        break;
      case 'Invalid email':
        errorMessage = 'Please enter valid e-mail address.';
        break;
      case 'Your password must contain at least 8 characters, at least 1 number, 1 upper case and 1 lower case letter.':
        errorMessage =
          'Your password must contain at least 8 characters, at least 1 number, 1 upper case and 1 lower case letter.';
        break;
      case 'Passwords should match':
        errorMessage = 'Passwords should match';
        break;
      default:
        errorMessage = 'All fields are required';
    }
    return errorMessage;
  };
  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const { formErrors, registerErrorAlertOpen, userIsTooYoung } = this.state;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const repeat_password = registerFormData.repeat_password;
    const birthday = registerFormData.birthday;
    const referral_code = registerFormData.referral_code;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const marketing_optin = registerFormData.marketing_optin;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const is_student = registerFormData.is_student;
    const push_notifications = registerFormData.push_notifications;
    const { loggedIn } = this.props.auth;
    const defaultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    const privacyPolicyLink =
      getConfig().general.privacyPolicyLink ||
      'https://www.francomanca.co.uk/legal/#privacy-cookies';

    return (
      <Loading transparent>
        <Layout
          hideSecondToolbar={true}
          hideBack={true}
          headerTitle={__('Register')}
          color="transparent"
          contentClassName="register-background"
          backHandler={this.backHandler}
        >
          <div className="absolute-content scrollable-y">
            <Spacer />
            {this.state.registerFirstBlock && (
              <>
                <IonList>
                  <div className="input-field-container">
                    <NormalText>{__('First Name*')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.first_name ? 'invalid' : ''}`}
                    >
                      <IonInput
                        onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={first_name}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('Surname*')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.last_name ? 'invalid' : ''}`}
                    >
                      <IonInput
                        onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                        required={true}
                        type="text"
                        pattern="text"
                        inputmode="text"
                        value={last_name}
                      ></IonInput>
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('E-mail Address*')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.email ? 'invalid' : ''}`}
                    >
                      <IonInput
                        onIonInput={(e) => {
                          this.handleInput('email', e.target.value);
                        }}
                        onIonBlur={(e) => this.handleInput('email', e.target.value)}
                        clearInput
                        required={true}
                        type="email"
                        pattern="email"
                        inputmode="email"
                        value={email}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('Mobile Number*')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.mobile ? 'invalid' : ''}`}
                    >
                      <IonInput
                        onIonInput={(e) => {
                          this.handleInput('mobile', e.target.value);
                        }}
                        onIonBlur={(e) => this.handleInput('mobile', e.target.value)}
                        clearInput
                        required={true}
                        type="mobile"
                        pattern="mobile"
                        inputmode="tel"
                        value={mobile}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                  </div>
                </IonList>
                <Spacer />
                <div className="flex-min">
                  <IonButton
                    expand="block"
                    color="secondary"
                    onClick={() => {
                      this.validateFirstBlockForm();
                    }}
                  >
                    {__('Next')}
                  </IonButton>
                  <IonButton
                    onClick={() => forwardTo('/dashboard')}
                    class="link underlined"
                    expand="block"
                  >
                    {__('Cancel')}
                  </IonButton>
                </div>
              </>
            )}
            {this.state.registerSecondBlock && (
              <>
                <NormalText>
                  {__(
                    'Your password must have at least 8 characters including 1 number, 1 uppercase and 1 lower case letter. ',
                  )}
                </NormalText>
                <Spacer size={1} />
                <IonList>
                  <div className="input-field-container">
                    <NormalText>{__('Password *')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.password ? 'invalid' : ''}`}
                    >
                      <IonInput
                        onIonInput={(e) => {
                          this.handleInput('password', e.target.value);
                        }}
                        onIonBlur={(e) => this.handleInput('password', e.target.value)}
                        clearInput
                        required={true}
                        type="password"
                        pattern="password"
                        inputmode="password"
                        value={password}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('Repeat Password *')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${
                        formErrors.repeat_password ? 'invalid' : ''
                      }`}
                    >
                      <IonInput
                        onIonInput={(e) => {
                          this.handleInput('repeat_password', e.target.value);
                        }}
                        onIonBlur={(e) => this.handleInput('repeat_password', e.target.value)}
                        clearInput
                        required={true}
                        type="password"
                        pattern="password"
                        inputmode="password"
                        value={repeat_password}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('DOB *')}</NormalText>
                    <IonItem
                      lines="none"
                      className={`input-field-wrapper ${formErrors.birthday ? 'invalid' : ''}`}
                    >
                      <DatePicker
                        className="data-picker-input"
                        display="bottom"
                        max={yesterday}
                        setText={__('Done')}
                        cancelText={__('Cancel')}
                        value={birthday}
                        defaultValue={defaultDate}
                        onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                        dateFormat="dd/mm/yy"
                      />
                    </IonItem>
                  </div>
                  <Spacer size={1} />
                  <div className="input-field-container">
                    <NormalText>{__('Referral Code (optional)')}</NormalText>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        onIonInput={(e) => {
                          this.handleInput('referral_code', e.target.value);
                        }}
                        onIonBlur={(e) => this.handleInput('referral_code', e.target.value)}
                        clearInput
                        required={true}
                        type="referral_code"
                        pattern="referral_code"
                        inputmode="referral_code"
                        value={referral_code}
                        autocomplete="on"
                      ></IonInput>
                    </IonItem>
                  </div>
                </IonList>
                <Spacer size={1} />
                <div className="box-holder box-holder--register">
                  <IonList>
                    <div lines="none">
                      <div tabIndex="-1"></div>
                      <div className="toggle">
                        <IonLabel>
                          <div className="register-terms-wrapper">
                            <IonCheckbox
                              color="secondary"
                              checked={is_student}
                              onIonChange={(e) => this.handleInput('is_student', e.detail.checked)}
                            />
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary">
                                {__('I am a student with valid ID card.')}
                              </NormalText>
                            </IonLabel>
                          </div>
                        </IonLabel>
                        <FieldError
                          className="field-error"
                          value={__(this.state.formErrors.is_student)}
                        />
                      </div>
                    </div>
                    <div lines="none">
                      <div tabIndex="-1"></div>
                      <div className="toggle">
                        <IonLabel>
                          <div className="register-terms-wrapper">
                            <IonCheckbox
                              color="secondary"
                              checked={accepted_terms_and_conditions}
                              onIonChange={(e) =>
                                this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                              }
                            />
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary">
                                {__('I agree to the')}{' '}
                                <span
                                  className="pointer underlined secondary-color"
                                  onClick={() => forwardTo('/terms')}
                                >
                                  {__('terms and conditions')}
                                </span>{' '}
                                {__('and')}{' '}
                                <span
                                  className="secondary-color  pointer underlined"
                                  onClick={() => window.open(privacyPolicyLink, '_blank')}
                                >
                                  {__('privacy policy')}
                                </span>
                              </NormalText>
                            </IonLabel>
                          </div>
                        </IonLabel>
                        <FieldError
                          className="field-error"
                          value={__(this.state.formErrors.accepted_terms_and_conditions)}
                        />
                      </div>
                    </div>
                    <div lines="none">
                      <div tabIndex="-1"></div>
                      <div className="toggle">
                        <IonLabel>
                          <NormalText color="primary">
                            {__(
                              'I would like to receive news, offers and competitions from Franco Manca.',
                            )}
                          </NormalText>
                        </IonLabel>
                        <div className="field-error" />
                      </div>
                      <div className="toggle">
                        <IonLabel>
                          <div className="register-terms-wrapper">
                            <IonCheckbox
                              color="secondary"
                              checked={marketing_optin}
                              onIonChange={(e) =>
                                this.handleInput('marketing_optin', e.detail.checked)
                              }
                            />
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary">{__('via email')}</NormalText>
                            </IonLabel>
                          </div>
                        </IonLabel>
                      </div>
                    </div>
                    <div lines="none">
                      <div tabIndex="-1"></div>
                      <div className="toggle">
                        <IonLabel>
                          <div className="register-terms-wrapper">
                            <IonCheckbox
                              color="secondary"
                              checked={push_notifications}
                              onIonChange={(e) =>
                                this.handleInput('push_notifications', e.detail.checked)
                              }
                            />
                            <IonLabel className="ion-text-wrap">
                              <NormalText color="primary">
                                {__('via SMS or push notification')}
                              </NormalText>
                            </IonLabel>
                          </div>
                        </IonLabel>
                      </div>
                    </div>
                  </IonList>
                </div>
                <div className="flex-min">
                  <IonButton
                    expand="block"
                    color="secondary"
                    onClick={() => {
                      this.validateForm();
                    }}
                  >
                    {__('Register')}
                  </IonButton>
                  <IonButton
                    onClick={() => forwardTo('/dashboard')}
                    class="link underlined"
                    expand="block"
                  >
                    {__('Cancel')}
                  </IonButton>
                </div>
              </>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={registerErrorAlertOpen}
          onDidDismiss={() => this.setState({ registerErrorAlertOpen: false })}
          header={__('Oh, no!')}
          message={__(`${this.formatErrorMessage(formErrors[Object.keys(formErrors)[0]])}`)}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('OK'),
              cssClass: 'two-button-secondary',
              // handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('Close'),
              cssClass: 'two-button-secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
        <IonAlert
          isOpen={userIsTooYoung}
          onDidDismiss={() => this.setState({ userIsTooYoung: false })}
          header={__('Oh, no!')}
          message={__('You must be 14 or over to use this app.')}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('OK'),
              cssClass: 'two-button-secondary',
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData, protectedReferrer } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    protectedReferrer,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Register));
