import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, SmallText, Spacer, Subtitle } from '../../components/common';
import SwipeableTabs from '../../components/swipeableTabs';
import {
  acceptUser,
  dismissUser,
  applyVoucher,
  removeVoucher,
  getMyTable,
  getRewards,
  getVouchers,
  leaveTable,
  validateVoucher,
} from '../../store/actions';
import { IonAlert, IonButton, IonInput, IonItem } from '@ionic/react';
import Modal from '../../components/modal';
import { forwardTo } from '../../lib/utils';
import moment from 'moment';
import {
  SET_APPLIED_VOUCHERS_MODAL,
  SET_COMMON_MODAL,
  SET_COMMON_PROP,
  SET_PENDING_VOUCHERS,
} from '../../store/constants';
import acceptIcon from '../../assets/images/icons/tick_icon.svg';
import declineIcon from '../../assets/images/icons/decline_icon.svg';
import acceptGif from '../../assets/images/swirl.gif';

class YourTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVouchersModalOpen: false,
      appliedVouchers: this.props.tableAppliedVouchers,
      leaveTableModalOpen: false,
      removeVoucherModalOpen: false,
      joinTableModel: true,
    };
  }
  componentDidMount() {
    const { __, tableData, profile } = this.props;
    const yourData = tableData?.customers?.filter(
      (customer) => customer.user_id == parseInt(profile.ctcid),
    );
    const voucher = this.props.location.state?.selectedVoucher;
    this.props.dispatch(
      getMyTable(true, () => {
        if (voucher) {
          this.setState({
            appliedVouchers: [
              ...this.props.tableAppliedVouchers,
              {
                description: voucher.Description,
                first_name: yourData?.[0]?.first_name,
                last_name: yourData?.[0]?.last_name,
                adding: true,
              },
            ],
          });
        }
      }),
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.tableAppliedVouchers.length !== this.props.tableAppliedVouchers.length ||
      prevProps.tableData !== this.props.tableData
    ) {
      this.setState({ appliedVouchers: this.props.tableAppliedVouchers });
    }
  }
  acceptUser = (user_id) => {
    const data = {
      id: this.props.tableData.id,
      user_id,
    };
    this.props.dispatch(acceptUser(data));
  };
  dismissUser = (user_id) => {
    const data = {
      id: this.props.tableData.id,
      user_id,
    };
    this.props.dispatch(dismissUser(data));
  };
  removeVoucherFromState = (code) => {
    const vouchers = JSON.parse(JSON.stringify(this.state.appliedVouchers));
    const voucherToRemove = vouchers.find((el) => el.code == code);
    voucherToRemove.removing = true;
    this.setState({ appliedVouchers: vouchers });
  };
  removeVoucherFromTable = () => {
    const voucher = this.state.voucherToRemove;
    this.removeVoucherFromState(voucher.code);
    this.props.dispatch(removeVoucher({ vouchercode: voucher.code, id: this.props.tableData?.id }));
  };
  render() {
    const { __, tableData, profile, is_pending } = this.props;
    const yourData = tableData?.customers?.filter(
      (customer) => customer.user_id == parseInt(profile.ctcid),
    );
    const tableUsers = tableData?.customers?.filter(
      (customer) => customer.user_id != parseInt(profile.ctcid),
    );
    const isLeadUser = yourData?.[0]?.is_leader;
    return (
      <Layout
        showHeader
        showFooter
        color="transparent"
        headerTitle={__('Faq & Support')}
        hideBack={true}
        hideSecondToolbar={true}
        noPadding
      >
        <div className=" scrollable-y absolute-content table-data-content">
          <div className="table-header">
            <NormalText className="secondary-color uppercase block centered">
              {__('WELCOME TO THE TABLE')}
            </NormalText>
            <NormalText className="uppercase block centered">
              {__('IF YOU ARE THE LEADER, ACCEPT YOUR FRIENDS WHEN THEY REQUEST TO JOIN.')}
            </NormalText>
          </div>

          <SwipeableTabs
            tabs={[
              {
                label: __(`Table ${tableData?.offset}`),
                tabContent: (
                  <div className="absolute-content">
                    <div className="table-card active-user-card">
                      <div className="table-content">
                        <SmallText color="gray">
                          {__(`${yourData?.[0]?.is_leader ? 'LEADER' : 'YOU'}`)}
                        </SmallText>
                        <NormalText className="table-name uppercase block">
                          {yourData?.[0]?.first_name} {yourData?.[0]?.last_name?.slice(0, 2)}
                        </NormalText>
                      </div>
                      <div className="table-actions">
                        {yourData?.[0]?.is_pending ? (
                          <IonButton className=" rounded-btn" color="primary">
                            {__('Waiting')}
                          </IonButton>
                        ) : (
                          <>
                            {!this.props.deletingUserPending ? (
                              <IonButton
                                onClick={() => this.setState({ leaveTableModalOpen: true })}
                                className=" rounded-btn"
                                color="primary"
                              >
                                {__('Leave')}
                              </IonButton>
                            ) : (
                              <>
                                <img style={{ width: '80px' }} src={acceptGif} />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <Spacer size={1} />
                    {tableUsers?.map((user) => (
                      <div className="table-card">
                        <div className="table-content">
                          <NormalText color="gray">
                            {__(`${user?.is_leader ? 'LEADER' : ''}`)}
                          </NormalText>
                          <NormalText className="table-name uppercase block">
                            {user?.first_name} {user?.last_name?.slice(0, 2)}
                          </NormalText>
                        </div>
                        <div className="table-actions">
                          {isLeadUser && user.is_pending ? (
                            <>
                              {this.props.pendingUsers.includes(user.user_id) ? (
                                <img style={{ width: '80px' }} src={acceptGif} />
                              ) : (
                                <>
                                  {' '}
                                  <img
                                    onClick={() => {
                                      this.props.dispatch({
                                        type: SET_COMMON_PROP,
                                        key: `pendingUsers`,
                                        value: [...this.props.pendingUsers, user.user_id],
                                      });
                                      this.acceptUser(user.user_id);
                                    }}
                                    src={acceptIcon}
                                  />
                                  <img
                                    onClick={() => this.dismissUser(user.user_id)}
                                    src={declineIcon}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <IonButton className=" rounded-btn" color="secondary">
                              {__('Joined')}
                            </IonButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                label: __('Rewards'),
                tabContent: (
                  <div className="absolute-content">
                    <div className="table-card active-user-card">
                      <div className="table-content">
                        <SmallText color="gray">
                          {__(`${yourData?.[0]?.is_leader ? 'LEADER' : 'YOU'}`)}
                        </SmallText>
                        <NormalText className="table-name uppercase block">
                          {yourData?.[0]?.first_name} {yourData?.[0]?.last_name?.slice(0, 2)}
                        </NormalText>
                      </div>
                      <div className="table-actions">
                        <IonButton
                          className="rounded-btn"
                          onClick={() => {
                            this.props.dispatch(getVouchers());
                            this.setState({ isVouchersModalOpen: true });
                          }}
                          color="secondary"
                        >
                          {__('Redeem')}
                        </IonButton>
                      </div>
                    </div>
                    <Spacer size={1} />
                    {this.state.appliedVouchers.map((voucher, key) => (
                      <div className="table-card" key={key}>
                        <div className="table-content">
                          <NormalText className="table-name uppercase block">
                            {voucher?.first_name} {voucher?.last_name?.slice(0, 2)}
                          </NormalText>
                          <NormalText color="gray">{voucher?.description}</NormalText>
                        </div>
                        <div className="table-actions">
                          {voucher.adding ? (
                            <>
                              <img style={{ width: '80px' }} src={acceptGif} />
                            </>
                          ) : (
                            <IonButton
                              className="rounded-btn"
                              onClick={() => {
                                this.setState({
                                  voucherToRemove: voucher,
                                  removeVoucherModalOpen: true,
                                });
                              }}
                              color={voucher.removing ? 'primary' : 'secondary'}
                            >
                              {voucher.removing ? __('Waiting') : __('Remove')}
                            </IonButton>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              },
            ]}
          ></SwipeableTabs>
        </div>
        <Modal
          className="table-vouchers-modal"
          isOpen={this.state.isVouchersModalOpen}
          onDiddismiss={() => this.setState({ isVouchersModalOpen: false })}
        >
          <Subtitle className="block centered uppercase regular-font" color="secondary">
            {__('You currently have:')}
          </Subtitle>
          <Spacer size={1} />
          {this.props.vouchers
            .filter((voucher) => !voucher.Redeemed)
            .map((voucher, key) => (
              <div className="table-card" key={key}>
                <div className="table-content">
                  <NormalText className="uppercase">{voucher.Description}</NormalText>
                  <SmallText className="uppercase block" color="gray">
                    {__('Received:')} {moment(voucher.URD).format('DD/MM/YYYY')}
                  </SmallText>
                  <SmallText className="uppercase block" color="gray">
                    {__('Expires:')} {moment(voucher.ExpiryDate).format('DD/MM/YYYY')}
                  </SmallText>{' '}
                </div>
                <div className="table-actions">
                  <IonButton
                    className="rounded-btn"
                    onClick={() => {
                      if (
                        this.state.appliedVouchers.findIndex(
                          (el) => el.code == voucher.VoucherCode,
                        ) == -1
                      ) {
                        this.setState({
                          appliedVouchers: [
                            ...this.state.appliedVouchers,
                            {
                              description: voucher.Description,
                              first_name: yourData?.[0]?.first_name,
                              last_name: yourData?.[0]?.last_name,
                              adding: true,
                            },
                          ],
                        });
                      }
                      this.props.dispatch(
                        applyVoucher({ code: voucher.VoucherCode, id: tableData?.id }),
                      );
                      this.setState({ isVouchersModalOpen: false });
                    }}
                    color="secondary"
                  >
                    {__('Redeem')}
                  </IonButton>
                </div>
              </div>
            ))}
          <Spacer size={1} />
          <NormalText
            onClick={() =>
              this.props.dispatch({ type: SET_COMMON_MODAL, modal: 'faqModalOpen', value: true })
            }
            className="block bold tertiary-color"
          >
            {__('Click here for full redemption rules')}
          </NormalText>
          <Spacer size={1} />

          <NormalText
            onClick={() => this.setState({ isVouchersModalOpen: false })}
            className="block bold tertiary-color"
          >
            {__('Close')}
          </NormalText>
          <Spacer size={1} />

          <Subtitle className="block centered uppercase regular-font" color="secondary">
            {__('Reward code?')}
          </Subtitle>
          <NormalText className="block centered">
            {__('IF YOU HAVE A REWARD CODE, ENTER IT HERE BEFORE REQUESTING YOUR BILL')}
          </NormalText>
          <Spacer size={1} />

          <div className="redeem-voucher-wrapper">
            <IonItem lines="none">
              <IonInput
                onIonInput={(e) => this.setState({ voucherCode: e.target.value })}
                value={this.state.voucherCode}
              ></IonInput>
            </IonItem>
            <IonButton
              disabled={!this.state.voucherCode}
              color="secondary"
              className="rounded-btn"
              onClick={() => {
                this.props.dispatch(
                  validateVoucher({ code: this.state.voucherCode, id: tableData?.id }),
                );
                this.setState({ isVouchersModalOpen: false });
              }}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </Modal>
        <IonAlert
          isOpen={this.props.appliedVoucherModalOpen}
          onDidDismiss={() =>
            this.props.dispatch({ type: SET_APPLIED_VOUCHERS_MODAL, value: false })
          }
          header={__('All done!')}
          message={__('Your reward has been added to the table. ')}
          cssClass="two-button-alert"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: 'ADD ANOTHER',
              role: 'confirm',
              handler: () => this.setState({ isVouchersModalOpen: true }),
            },
            {
              cssClass: 'two-button-gray',
              text: 'Back to table',
              role: 'cancel',
            },
          ]}
        />
        <IonAlert
          onDidDismiss={() => this.setState({ leaveTableModalOpen: false })}
          isOpen={this.state.leaveTableModalOpen}
          header={__('So soon?')}
          message={__('If you leave, any rewards you have added will stay on the table.')}
          cssClass="two-button-alert"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: "OK I'm off",
              role: 'confirm',
              handler: () => {
                this.setState({ leaveTableModalOpen: false })
                this.props.dispatch(leaveTable(tableData.id))
              },
            },
            {
              cssClass: 'two-button-gray',
              text: 'Wait! I will stay',
              role: 'cancel',
              handler: () => {
                this.setState({ leaveTableModalOpen: false })
              },
            },
          ]}
        />
        {this.state.joinTableModel && yourData && (
          <IonAlert
            isOpen={this.props.joinTableModalOpen}
            onDidDismiss={() => {
              this.setState({ joinTableModel: false });
              this.props.dispatch({
                type: SET_COMMON_MODAL,
                modal: 'joinTableModalOpen',
                value: false,
              });
            }}
            header={!isLeadUser && yourData?.[0]?.is_pending ? 'Request sent' : 'All done!'}
            message={
              !isLeadUser && yourData?.[0]?.is_pending
                ? 'The table leader must accept your request to complete the check in'
                : 'Enjoy your meal, at the end of your visit your stamps will be updated.'
            }
            cssClass="no-table-alert one-red-button-alert"
            buttons={[
              {
                cssClass: 'two-button-secondary',
                text: __('OK great'),
              },
            ]}
          />
        )}
        <IonAlert
          onDidDismiss={() => this.setState({ removeVoucherModalOpen: false })}
          isOpen={this.state.removeVoucherModalOpen}
          header={__('Alert')}
          message={__('This will remove your reward from the bill. Do you wish to continue?')}
          cssClass="two-button-alert"
          buttons={[
            {
              cssClass: 'two-button-secondary',
              text: 'Yes, remove it',
              role: 'confirm',
              handler: () => this.removeVoucherFromTable(),
            },
            {
              cssClass: 'two-button-gray',
              text: 'Cancel',
              role: 'cancel',
            },
          ]}
        />
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    tableData: state.orders.tableData,
    profile: state.profile.profile,
    vouchers: state.profile.vouchers,
    pendingUsers: state.common.pendingUsers,
    tableAppliedVouchers: state.orders.tableAppliedVouchers,
    voucherValidated: state.orders.voucherValidated,
    appliedVoucherModalOpen: state.orders.appliedVoucherModalOpen,
    joinTableModalOpen: state.common.joinTableModalOpen,
    deletingUserPending: state.common.deletingUserPending,
  };
};

export default connect(stateToProps)(withTranslation(YourTable));
