import React from 'react';

import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import {
  Title,
  Spacer,
  NormalText,

} from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import {
  forwardTo,
  goBack,
  isDefined,
} from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import './index.css';

import { globalpayOrderPayment, loading, setAccessToken, setCardToken, setGlobalpayAut, setUserCards, showToast } from '../../store/actions';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import moment from '../../lib/moment';
import api from '../../lib/api';
import { getEnv } from '../../lib/env';
import { SET_COMMON_PROP, SET_ORDERS_PROP } from '../../store/constants';
import { chevronBack, returnDownBack } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const { getMobile, setMobile } = Basket;

class CardAdd extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    const defaultCard =
      this.props.cards.length > 0 &&
      this.props.profile.cardToken &&
      this.props.cards.find((card) => card.id === this.props.profile.cardToken);
    this.state = {
      collectedModal: false,
      collectedPay: this.props.cards.length === 0,
      showAllCards: false,
      pickTime: null,
      removeCardAlert: null,
      cards: [],
    };
  }

  async componentDidMount() {
    Basket.setPMTSelection(false)
    await this.props.dispatch(loading(true))

    const data = await api.getAccessToken()
    this.props.dispatch(setAccessToken(data.data.token))
    this.props.dispatch(setUserCards(data.data.payment_methods))
    this.props.dispatch(setCardToken(data.data.card_token))
    await this.props.dispatch(loading(false))


    const style = getComputedStyle(document.documentElement)
    const secondaryColor = style.getPropertyValue('--ion-color-secondary');
    const btnFont = style.getPropertyValue('--okx-subtitle-font-size');
    const inputFont = style.getPropertyValue('--ion-font-size');

    const font = new FontFace("myfont", "url(../../assets/fonts/FrancoMancaBold.woff)", {
      style: "normal",
    });
    GlobalPayments.configure({
      accessToken: data.data.card_token,
      apiVersion: "2021-03-22",
      env: getConfig().general.globalPayEnv,
      reference: `${this.props.profile.email.replace(/['`{}=?^!#$%&*@+,|]/g, '_')}${this.props?.location?.state?.saveCard ? '' : '_false'}`
    });
    const cardForm = GlobalPayments.ui.form({
      fields: {
        "card-number": {
          placeholder: "•••• •••• •••• ••••",
          target: "#card-number"
        },
        "card-expiration": {
          placeholder: "MM / YYYY",
          target: "#card-expiration"
        },
        "card-cvv": {
          placeholder: "•••",
          target: "#card-cvv"
        },
        "card-holder-name": {
          placeholder: "Jane Smith",
          target: "#card-holder-name"
        },
        "submit": {
          target: "#submit-button",
          value: "Pay Now",
        }
      },
      styles: {
        "iframe": {
          "width": "100%"
        },
        "input":{
          "font-size":`${inputFont}`
        },
        //adding a radius on all text inputs
        "input[type=text]": {
          "margin-bottom": "20px",
          "padding": "12px",
          "border": 'none;',  
        },
        //adding focus on the fields when selected
        "input[type=text]:focus-visible, input[type=tel]:focus-visible": {
          "outline": "none !important",
        },

        //adding a radius on all number inputs
        "input[type=tel]": {
          "margin-bottom": "20px",
          "padding": "12px",
          "border": "1px solid white",
          "border-radius": "10px"
        },

        //adding focus on the fields when selected
        "button[type=button]:focus-visible": {
          "background-color": "#71C5E8",
          "outline": "none !important",
        },

        //adding styling to the submit button
        "button[type=button]": {
          "background-color": `${secondaryColor}`,
          "border-radius": "30px",
          "color": "white",
          "padding": "12px",
          "margin": "10px",
          "border": "none",
          "cursor": "pointer",
          "font-size": `${btnFont}`,
          'font-family': `${font}`,
          "clip-path": "polygon(0 93%,0 1%,19% 1%,32% 0,55% 3%,80% 0,100% 0,100% 53%,100% 85%,100% 100%,0 100%)"
        },


      }
    });

    // method to notify that hosted fields have been initialized
    cardForm.ready(() => {
      //TODO: Add your successful message
    });

    // appending the token to the form as a hidden field and
    // submitting it to the server-side
    cardForm.on("token-success", async (resp) => {
      try {
        if (!await Basket._isCollectionTimeStillValid()) {
          return
        }
        Basket.setSelectedCard({
          last_four: resp.details.cardLast4,
          brand: resp.details.cardType,
          location:'CARD ADD',
          card_json:JSON.stringify(resp)
        })
        Basket.setPMT(resp.paymentReference)
        Basket.createOrder()
      }
      catch (err) {
        this.props.dispatch(showToast(this.props.__('Payment failed'), 'warning'));
        this.props.dispatch({
          type: SET_COMMON_PROP,
          key: `paymentProcessCompleted`,
          value: true,
        });

      }

    });

    // add error handling if token generation is not successful
    cardForm.on("token-error", async (resp) => {
      if(resp.reasons[0].code == "INVALID_REQUEST_DATA"){
        this.props.dispatch(showToast(this.props.__('Incorrect data. Please make sure that your card details are correct.'), 'warning'));
      }else{
        this.props.dispatch(showToast(this.props.__('An error occured. Please try again.'), 'warning'));
      }
      const data = await api.getAccessToken()
      this.props.dispatch(setAccessToken(data.data.token))
      this.props.dispatch(setUserCards(data.data.payment_methods))
      this.props.dispatch(setCardToken(data.data.card_token))

      // TODO: Add your error handling
    });

    // field-level event handlers. example:
    cardForm.on("card-number", "register", () => {
    });
    // }



  }




  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else {
      goBack();
    }
  };

  render() {
    const {
      __,

      restaurants,
    } = this.props;

    const basketInstance = this.props.basketInstance || Basket;

    let timePickerOptions = [];
    let snoozedTimes = [];
    let minDT = moment();
    if (Basket.getRestaurant()) {
      const store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant().id);
      if (Basket.getDeliveryOption().id !== 'table')
        snoozedTimes = checkSnoozedTimes(store, Basket.getDeliveryOption().id);
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      timePickerOptions = formatDataForTime(
        Basket.getRestaurant(),
        minDT,
        Basket.getRestaurant().id,
        Basket.getDeliveryOption().id === 'charter-delivery' ? true : false,
        false,
        snoozedTimes,
      );
    }

    const { payments } = getConfig()?.services;
    const { webPay } = payments;
    return (
      <>
        <Loading transparent>
          <Layout
            showHeader
            headerWithTitle={true}
            hideSecondToolbar={true}
            color="transparent"
            headerTitle={__('Checkout')}
            backHandler={goBack}
            showBack
          >
            <div className='page-secondary-header' style={{ flex: 'unset' }}>
              <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>

              <Title className="secondary-font dark-color">{__('Payment details')}</Title>
            </div>
            <div className='absolute-content'>
              <form id="payment-form" >


                <div className="input-field-container">
                </div>
                <label for="card-number">Card Number</label>
                <div id="card-number"></div>
                <label for="card-expiration">Expiry Date</label>
                <div id="card-expiration"></div>
                <label for="card-cvv">Security Code</label>
                <div id="card-cvv"></div>
                <label for="card-holder-name">Cardholder Name</label>
                <div id="card-holder-name"></div>
                <Spacer size={1} />
                <div style={{ margin: "0 25px" }} className='centered'>
                  <NormalText className='block' color="primary">
                    {__('By placing this order I agree to Franco Manca') + ' '}{' '}
                    <span
                      className="pointer underlined secondary-color"
                      onClick={() => forwardTo('/terms')}
                    >
                      {__('terms and conditions')}
                    </span>{' '}
                    {__('and')}{' '}
                    <span
                      className="secondary-color  pointer underlined"
                      onClick={() => forwardTo('/privacy')}
                    >
                      {__('privacy policy')}
                    </span>
                  </NormalText>
                </div>
                <Spacer size={1} />

                <div id="submit-button"></div>
              </form>

            </div>
          </Layout>

        </Loading>


      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
    deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
    restaurants: store.restaurants.restaurants || [],
    token: store.orders.token || '',
    cards: store.orders.cards || [],
    orderHistory: store.orders.orderHistory || []
  };
};

export default connect(mapStateToProps)(withTranslation(CardAdd));
