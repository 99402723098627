import React, { Component } from 'react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { connect } from 'react-redux';
import { verifyCode } from '../../store/actions';
import Loading from '../../components/spinner';
import './index.css';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import SplitedInput from '../../components/splitedInput';
import { isWebConfig } from '../../lib/utils';
import { chevronBack } from 'ionicons/icons';

class SendCode extends Component {
  state = {
    code: '',
    predefinedCode: false,
  };
  componentDidMount = async () => {
    let signal = new AbortController();

    setTimeout(() => {
      // abort after 10 minutes
      signal.abort();
    }, 10 * 60 * 1000);
    if ('OTPCredential' in window) {
      try {
        if (navigator.credentials) {
          try {
            await navigator.credentials
              .get({
                otp: { transport: ['sms'] },
                signal: signal.signal,
              })
              .then((otp) => {
                this.setState({ code: otp.code, predefinedCode: otp.code });
              })
              .catch((err) => {
                console.log(err);
              });
          } catch (e) {
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  handleInputChange = (value) => {
    this.setState({ code: value });
  };

  validateCode() {
    const { code, predefinedCode } = this.state;
    let validationCode = code;
    if (predefinedCode) {
      validationCode = predefinedCode;
    }
    const email = this.props.registerFormData.email;
    this.props.dispatch(verifyCode({ code: validationCode, email }));
  }

  render() {
    const { __, profile } = this.props;
    const { code } = this.state;
    return (
      <Loading>
        <Layout
          showBack
          showHeader
          hideSecondToolbar={true}
          color="transparent"
          headerTitle={__('Terms & Conditions')}
        >
          <div className='page-secondary-header' style={{ flex: 'unset' }}>
            <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>
            <Title className="secondary-font dark-color">{__('Confirm your mobile')}</Title>
          </div>
          <div className='absolute-content'>

            <StrongText className="block centered">
              {__(
                'We have sent you a 6 digit code to your SMS. Please type this in to confirm your telephone number',
              )}
            </StrongText>
            <Spacer />
            {isWebConfig() ? <>
              <div className="input-field-container">
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    onIonInput={(e) => this.handleInputChange(e.target.value)}
                    placeholder='CODE'
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={code}
                  />
                </IonItem>
              </div>

            </> :
              <div className="code-wrapper">
                <SplitedInput
                  predefinedCode={this.state.predefinedCode}
                  format="IIIIII"
                  text={code}
                  valueHandler={this.handleInputChange}
                  inputProps={{
                    type: 'number',
                    inputmode: 'tel',
                  }}
                />
              </div>

            }

            <Spacer />
            <IonButton
              onClick={() => {
                this.validateCode();
              }}
              color="secondary"
              expand="block"
            >
              {__('Confirm')}
            </IonButton>
          </div>

        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile, registerFormData } = state.profile;

  return {
    profile,
    registerFormData,
  };
};

export default connect(stateToProps)(withTranslation(SendCode));
