import React from 'react';
import moment from '../../lib/moment';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert, IonIcon, IonSpinner } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import {
  Title,
  NormalText,
  Spacer,
  StrongText,
  Subtitle,
  SmallText,
} from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, isEmptyObject, isWebConfig, visaEndingDrawText } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import Basket from '../../lib/basket';
import AmountPaid from '../../components/amountPaid';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import './index.css';
import { loading, logout } from '../../store/actions';
import { chevronBack } from 'ionicons/icons';
import { GET_ORDER_HISTORY, SET_COMMON_PROP, SET_ORDERS_PROP } from '../../store/constants';
import { printOrderPayment } from '../historyDetails';
import api from '../../lib/api';

class OrderCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,

    };
  }
  drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;
    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <div className="flex-justify-between flex-col-wrapper">
              <SmallText className='uppercase dark-color'>+ {Basket.getProductName(choice, profile)}
                {parseInt(choice.productPrice) > 0 &&
                  <span className='subchoice-price secondary-color'>+{Basket.formatPrice(choice.productPrice)}</span>
                }
              </SmallText>


            </div>

          );
        });
      });
    }
    return null;
  };
  async componentDidMount() {
    var _this = this
    var _props = this.props
    var _body = document.body
    if(parseInt(Basket.getTotal())===0){
      Basket.reset()
    }
    window.addEventListener('message', function (event) {
      if (event.data == 'GLOBALPAY_WINDOW_CLOSED') {
        _this.setState({ orderFailed: false })
        Basket.reset()
        _props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
        _props.dispatch({
          type: SET_COMMON_PROP,
          key: `paymentProcessCompleted`,
          value: true,
        });
        _props.dispatch(loading(false))
        _body.removeChild(document.querySelector('[id^=GlobalPayments-overlay]'))

      }

      if (event.data == 'GLOBALPAY_WINDOW_CLOSED_FAILED') {
        _this.setState({ orderFailed: true })
        _props.dispatch({
          type: SET_COMMON_PROP,
          key: `paymentProcessCompleted`,
          value: true,
        });
        _props.dispatch(loading(false))
        _body.removeChild(document.querySelector('[id^=GlobalPayments-overlay]'))
        forwardTo('/order')

      }
    });
    if (this.props.location?.state?.removeLoader) {
      Basket.reset()

      const orders = await api.getOrderHistory()
      this.props.dispatch({
        type: SET_ORDERS_PROP,
        key: `orderHistory`,
        value: orders,
      });
      this.setState({ last_order: orders[0] }, () => {
        this.props.dispatch({
          type: SET_COMMON_PROP,
          key: `paymentProcessCompleted`,
          value: true,
        });
      })

    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location?.state?.removeLoader && this.props.location?.state?.removeLoader != prevProps.location?.state?.removeLoader) {
      this.props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
      setTimeout(() => {
        this.props.dispatch({
          type: SET_COMMON_PROP,
          key: `paymentProcessCompleted`,
          value: true,
        });
      }, 3000);
    }
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        const now = moment();
        let cutoffTime = orderArr ? orderArr.cutoff_time : now;
        let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
        const utcOffset = cutoffTimeMoment.utcOffset();
        cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
        if (now.isBefore(cutoffTimeMoment)) {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {__('Click And Collect')} {__('Order')} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage, lastOrder) => {
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Collect from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Collect at')}:{' '}
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </SmallText>
              <SmallText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{lastOrder?.status && __(lastOrder.status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Delivery from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Order placed on')}: {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
              </SmallText>
              <SmallText className="block">
                {__('Delivery at')}: {basketInstance.formatOrderTime(true)}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{lastOrder?.status && __(lastOrder.status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Drop-off at')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <SmallText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </SmallText>
              <SmallText className="block">
                {__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
              </SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{lastOrder?.status && __(lastOrder.status)}</div>
            </div>
          </div>
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Ordered at')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <StrongText className="block">
                {__('Table Number')}-{order.table_name}
              </StrongText>
              <SmallText>{moment(order.created_at).format('D MMM YYYY [at] h:mm a')}</SmallText>
            </div>
            <div className="order-status-wrapper">
              <div>{lastOrder?.status && __(lastOrder.status)}</div>
            </div>
          </div>
        </>
      );
    }
  };
  drawOrder = (order, orderCompletePage) => {
    const { __, cards, lastOrder, latestOrderEvent } = this.props;
    const { id } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    return (
      <>
        {!isOldLayoutOfConfirmationScreens ? (
          <>
            {isWebConfig() &&
              this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            {this.drawContent(__, order, orderType, basketInstance, orderCompletePage, lastOrder)}
            <Spacer size={2} />
            <Subtitle className="bold">{__('Items Ordered')}</Subtitle>
            <OrderContent basketInstance={basketInstance} type="orderHistory" />
            <Spacer size={2} />
            <AmountPaid order={order} cards={cards} />
            {/* </Box> */}
            {orderType !== 'Click & Collect' ? (
              this.state.isCancel &&
                ['PAID', 'PARSED', 'NEW', 'new', 'paid', 'parsed'].indexOf(order.status) !== -1 &&
                hasCancelOrder ? (
                <IonButton
                  expand="block"
                  fill="clear"
                  className="link underlined"
                  color="tertiary"
                  onClick={() => this.handleCancelOrderModal(true)}
                >
                  {__('Cancel this order')}
                </IonButton>
              ) : null
            ) : null}
            <Spacer size={1} />


          </>
        ) : (
          <>
            <div className="order-status">
              {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
            </div>
            {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            {isOldLayoutOfConfirmationScreens ? (
              <div className="order-status-payment-method">
                <IonCol size="5" className="mr-10">
                  <StrongText tag="strong">{__('Payment Method')}:</StrongText>
                </IonCol>
                <IonCol>
                  <NormalText color="primary" className="thiner-text">
                    {visaEndingDrawText(paymentMetod)}
                  </NormalText>
                </IonCol>
              </div>
            ) : null}
            <Spacer size={1} />
            <Box>
              <BoxHeader>
                {/* <p className="light-text">
										<NormalText tag="strong">{ __('Order Location') }:</NormalText> <NormalText>{ order.restaurant_name }</NormalText><br />
									</p>
									<p className="light-text">
										<NormalText tag="strong">{ __('Order Time') }:</NormalText> <NormalText>{ basketInstance.formatOrderTime() }</NormalText><br />
									</p> */}
                {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
              </BoxHeader>
              <Spacer size={2} />
              <StrongText className="bold">{__('Items Ordered')}</StrongText>
              <OrderContent basketInstance={basketInstance} type="orderHistory" />
              <AmountPaid order={order} cards={cards} />
            </Box>
          </>
        )}
      </>
    );
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { lastOrder, order } = this.props;
    const _order = order || lastOrder;
    if (_order.status.toLowerCase() == 'captured') {
      Basket.reset();

    }
    forwardTo('/order')
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  render() {
    const { __, lastOrder, profile, cards } = this.props;
    const order = this.state.last_order || this.props.order || lastOrder;
    const orderCompletePage = true;
    const headerLabel = `${__('Order')} ${order?.id && `#${order.id}`}`;
    const withoutZ = order.collection_time.replace('Z', '');
    const transDate = Basket.getDate(withoutZ);
    return (
      <Loading>
        <Layout
          headerWithTitle={true}
          hideSecondToolbar={true}
          color="transparent"
          headerTitle={headerLabel}
          backHandler={this.backHandler}
          showHeader
          showBack
        >
          < >

            {!this.props.paymentProcessCompleted ? <div className="content-spinner">
              <IonSpinner color="primary" name="bubbles" />
            </div> : <>
              {order ?
                (
                  <div className="flex-row-wrapper absolute-content">

                    <div className='page-secondary-header' style={{ flex: 'unset' }}>
                      <IonIcon onClick={() => this.backHandler()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>
                      <Title className="secondary-font dark-color">{__(headerLabel)}</Title>
                    </div>
                    <div className="scrollable-y">
                      <div className='order-header-content-wrapper'>
                        {
                          ((this.state.orderFailed || (order.status.toLowerCase() !== 'captured' && typeof this.state.orderFailed === 'undefined'))) &&
                          <div
                            className={
                              'pill ellipsis pill-large'
                            }
                          >
                            {__('Order failed')}
                          </div>
                        }
                        <Subtitle className='mb-10 secondary-font dark-color  block'>{__('Collect from')}</Subtitle>

                        <NormalText className=' block dark-color' style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <span>{order.restaurant_name}</span>
                        </NormalText>
                        <NormalText style={{ display: 'flex', justifyContent: 'space-between' }} className=' dark-color'>
                          <span>{__('Collection time')} </span>
                          <span>{transDate.format('DD MMM YYYY [at] h:mm a')}</span>
                        </NormalText>
                      </div>
                      <Spacer size={1} />
                      <IonGrid className="box-wrapper order-content-wrapper paddL">
                        <Subtitle className="mb-15 block secondary-font dark-color">
                          {__('Your order')}
                        </Subtitle>
                        <div className='basket-items-wrapper'>
                          {order.items.map((basketItem, basketItemIndex) => {

                            const { quantity, item, instructions, selectedChoices } = basketItem;
                            let image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : '';
                            if (image && image.indexOf('http://') !== -1) {
                              image = image.replace(/http:\/\//g, 'https://');
                            }
                            return (
                              <div className="box-content">
                                <div
                                  className="order-item-image"
                                  style={image && image !== '' ? { backgroundImage: `url(${image})` } : { backgroundSize: 'contain' }}
                                />
                                <div className="item-content-wrapper flex-justify-between flex-row-wrapper">
                                  <div style={{ paddingRight: '50px' }}>
                                    <NormalText className="block  dark-color">{Basket.getProductName(item, profile)}</NormalText>
                                    {this.drawSubItemsText(basketItem, basketItemIndex)}
                                    {basketItem.instructions !== '' && (
                                      <>
                                        <Spacer size={1} />
                                        <SmallText className="block">{`${__('Instructions')}: ${basketItem.instructions}`} </SmallText>
                                      </>
                                    )}
                                  </div>
                                  <div className="item-content-actions flex-justify-between flex-col-wrapper flex-align-end">
                                    <div></div>
                                    <NormalText className='dark-color secondary-font'>
                                      {Basket.formatPrice((parseFloat(item.productPrice) * quantity) + selectedChoices.reduce((acc, obj) => acc + (parseFloat(obj[0]?.productPrice) || 0), 0))}
                                    </NormalText>
                                  </div>
                                </div>
                              </div>

                            )

                          })}

                        </div>

                        {(order.amount_paid > 0 || order.status.toLowerCase() == 'captured') && <>
                          {order.applied_discounts.length > 0 || order.applied_vouchers.length > 0 &&

                            <IonRow
                              className='amount-values'
                            >
                              <IonCol className="paddLR text-start">
                                <NormalText className="secondary-font dark-color ">{__('Items total')}</NormalText>
                              </IonCol>
                              <IonCol className="righted paddLR">
                                <NormalText className="secondary-font dark-color ">
                                  {Basket.formatPrice((order.amount_paid + Math.abs(order.discount)) / 100)}
                                </NormalText>

                              </IonCol>
                            </IonRow>
                          }

                          {order.applied_vouchers.length > 0 ? <>
                            <>
                              <IonRow
                                className='amount-values'
                              >
                                <IonCol size='8' className="paddLR text-start">
                                  <NormalText className="secondary-font dark-color ">{__(`${order.applied_vouchers[0]?.reward?.name?.charAt(0)?.toUpperCase()}${order.applied_vouchers[0]?.reward?.name?.substring(1)}`)}</NormalText>
                                </IonCol>
                                <IonCol size='4' className="righted paddLR">
                                  <NormalText className="secondary-font dark-color ">
                                    {Basket.formatPrice(order.discount / 100)}
                                  </NormalText>

                                </IonCol>
                              </IonRow>
                            </>


                          </> : null}

                          {order.applied_discounts &&
                            order.applied_discounts.map(discount => {
                              return (
                                <div key={discount.id}>
                                  <IonRow
                                    className="amount-values"

                                  >
                                    <IonCol className="paddLR grow ">
                                      <NormalText className='secondary-font dark-color'>{__(discount.name)}</NormalText> </IonCol>
                                    <IonCol className="righted paddLR">
                                      <NormalText className='secondary-font dark-color'>{Basket.formatPrice(discount.cost / 100 * -1)}</NormalText>


                                    </IonCol>
                                  </IonRow>

                                </div>
                              )
                            })
                          }

                        </>}
                        <IonRow
                          className='amount-values'
                        >
                          <IonCol className="paddLR text-start">
                            <NormalText className="secondary-font dark-color ">{__('Total paid')}</NormalText>
                          </IonCol>
                          <IonCol className="righted paddLR">
                            <NormalText className="secondary-font dark-color ">
                              {Basket.formatPrice(order.amount_paid / 100, true)}
                            </NormalText>

                          </IonCol>
                        </IonRow>
                        {!isEmptyObject(order.card)
                          &&
                          printOrderPayment(__, order)

                        }
                      </IonGrid>

                    </div>
                    {/* <AmountPaid order={order} cards={cards} /> */}

                  </div>


                )
                :
                this.noOrder()}
            </>}
          </>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal, latestOrderEvent } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;

  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    latestOrderEvent,
    profile,
    paymentProcessCompleted: store.common.paymentProcessCompleted
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
