import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import {
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import {
  forwardTo,
  goBack,
  isWebConfig /*, isEmptyObject*/,
  isDefined,
} from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import './index.css';
import { loading, removePaymentCard, setAccessToken, setCardToken, setUserCards } from '../../store/actions';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import moment from '../../lib/moment';
import Mobiscroll from '../../components/mobiscroll';
import { cardOutline, chevronBack, removeCircleOutline, trashBin } from 'ionicons/icons';
import api from '../../lib/api';
import { SET_COMMON_PROP } from '../../store/constants';
import { showToast } from '../../store/actions';
const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.getPlatform() === 'web';
class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    const defaultCard =
      this.props.cards.length > 0 &&
      this.props.profile.cardToken &&
      this.props.cards.find((card) => card.id === this.props.profile.cardToken);
    this.state = {
      collectedModal: false,
      collectedPay: this.props.cards.length === 0,
      showAllCards: false,
      pickTime: null,
      removeCardAlert: null,
      cards: [],
      saveCardAlertOpen: false
    };
  }

  async componentDidMount() {
    this.props.dispatch(loading(true))
    const data = await api.getAccessToken()
    this.setState({
      token: data.data.token,
      cards: data.data.payment_methods,
      card_token: data.data.card_token
    })
    this.props.dispatch(setAccessToken(data.data.token))
    this.props.dispatch(setCardToken(data.data.card_token))
    this.props.dispatch(setUserCards(data.data.payment_methods))
    this.props.dispatch(loading(false))

  }
  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (this.props.cards.length !== prevProps.cards.length) {
      this.setState({ cards: this.props.cards })
    }

  }

  changeSelectedPaymentCard = (cardId) => {
    this.setState({ selectedCard: cardId })
    Basket.setPMT(cardId)
    Basket.setPMTSelection(true)
    const selectedCard = this.state.cards.filter(el => el.id == cardId)
    Basket.setSelectedCard({
      last_four: selectedCard[0].card.number_last4.substr(-4),
      brand: selectedCard[0].card.brand,
      location:'CHECKOUT',
      card_json:JSON.stringify(selectedCard[0])
    })
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true, selectedMenu: [] });
    } else {
      goBack();
    }
  };

  showCollectedModal(modalOpen) {
    this.setState({ collectedModal: modalOpen });
  }
  changeTime = (selectedTime, minDT) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    const formattedDT = moment(minDT).hours(h).minutes(m);
    Basket.setCollectionTime(formattedDT);
  };

  setPickTime = (inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
      this.setState({ pickTime: inst.getVal() });
    }
  };

  removePaymentCard = () => {
    const { __ } = this.props;
    this.props.dispatch(
      removePaymentCard({ token: this.state.card_token, pmt: this.state.removeCardAlert }, {
        __,
        cb: () => this.setState({ removeCardAlert: null }),
      }),
    );
  };

  handleRemoveCardAlert = (cardId) => {
    this.setState({ removeCardAlert: cardId })
  };
  componentWillUnmount() {
    this.props.dispatch(loading(false))

  }

  handleOrderCreation = async () => {

    try {
      if (!await Basket._isCollectionTimeStillValid()) {
        return
      }
      Basket.createOrder()
      return

    }
    catch (err) {
      this.props.dispatch(showToast(this.props.__('Payment failed'), 'warning'));
      this.props.dispatch({
        type: SET_COMMON_PROP,
        key: `paymentProcessCompleted`,
        value: true,
      });

    }

  }
  render() {
    const {
      __,

      restaurants,
    } = this.props;
    const { saveCardAlertOpen ,removeCardAlert } = this.state
    const basketInstance = this.props.basketInstance || Basket;

    let timePickerOptions = [];
    let snoozedTimes = [];
    let minDT = moment();
    if (Basket.getRestaurant()) {
      const store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant().id);
      if (Basket.getDeliveryOption().id !== 'table')
        snoozedTimes = checkSnoozedTimes(store, Basket.getDeliveryOption().id);
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      timePickerOptions = formatDataForTime(
        Basket.getRestaurant(),
        minDT,
        Basket.getRestaurant().id,
        Basket.getDeliveryOption().id === 'charter-delivery' ? true : false,
        false,
        snoozedTimes,
      );
    }

    const { payments } = getConfig()?.services;
    const { webPay } = payments;
    return (
      <>
        <Loading transparent>
          <Layout
            showHeader
            headerWithTitle={true}
            hideSecondToolbar={true}
            color="transparent"
            headerTitle={__('Checkout')}
            backHandler={goBack}
            showBack
          >
            {/* {this.state?.cards.map(card => (
              <IonButton onClick={() => Basket.setPMT(card.id)}>{card.id}</IonButton>
            ))} */}
            <div className='page-secondary-header' style={{ flex: 'unset' }}>
              <IonIcon onClick={() => goBack()} className='page-secondary-header-back' color="gray" icon={chevronBack}></IonIcon>

              <Title className="secondary-font dark-color">{__('Checkout')}</Title>
            </div>
            <div className='absolute-content'>

              <IonRadioGroup onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)} value={this.state.selectedCard}>

                {this.state?.cards.map((card) => {
                  const { number_last4, brand, expiry_month, expiry_year } = card.card;
                  const { id } = card
                  return (
                    <IonItem lines="none" className="box-content checkout-card " key={id}>
                      <IonIcon slot="start" icon={cardOutline}></IonIcon>

                      <IonLabel className="ion-text-wrap">
                        <StrongText className="dark-color block">**** **** **** {number_last4.substr(-4)}</StrongText>
                        <SmallText className='secondary-font'>
                          {__(brand)} - {expiry_month}/{expiry_year}
                        </SmallText>
                      </IonLabel>
                      <IonRadio
                        color={isWebConfig() ? 'secondary' : 'white'}
                        slot="start"
                        value={id}
                      />
                      {!getConfig().flags.removeCardHidden &&
                           <IonButton onClick={() => this.handleRemoveCardAlert(id)}  className="link">
                           <IonIcon icon={removeCircleOutline} />
                         </IonButton>
                      }
                 
                    </IonItem>
                  )
                })}
              </IonRadioGroup>
              <IonButton
                onClick={() => this.setState({ saveCardAlertOpen: true })}
                class="link underlined"
                expand="block"
              >{this.state?.cards.length > 0 ? __('Add another card') : __('Add card')}</IonButton>
              <Spacer size={1} />
              <div style={{ margin: "0 25px" }} className='centered'>
                <NormalText className='block' color="primary">
                  {__('By placing this order I agree to Franco Manca') + ' '}{' '}
                  <span
                    className="pointer underlined secondary-color"
                    onClick={() => forwardTo('/terms')}
                  >
                    {__('terms and conditions')}
                  </span>{' '}
                  {__('and')}{' '}
                  <span
                    className="secondary-color  pointer underlined"
                    onClick={() => forwardTo('/privacy')}
                  >
                    {__('privacy policy')}
                  </span>
                </NormalText>
              </div>

              <Spacer size={2} />
              {this.state?.cards?.length > 0 &&
                <IonButton disabled={!this.state.selectedCard} onClick={() => this.handleOrderCreation()} color='secondary' expand='block'>{__('Pay')}</IonButton>

              }
            </div>
          </Layout>
          <IonAlert
            isOpen={saveCardAlertOpen}
            onDidDismiss={() => this.setState({ saveCardAlertOpen: false })}
            header={__('Confirm')}
            cssClass="two-button-alert"

            message={__('Do you you want to save this card for future use?')}
            buttons={[
              {
                text: __('Yes'),
                cssClass: 'secondary',
                handler: () => { this.setState({ saveCardAlertOpen: false }); Basket.setPMTSingle(false); forwardTo('/card-add', { saveCard: true }) }
              },
              {
                text: __('No'),
                cssClass: 'two-button-gray',
                handler: () => { this.setState({ saveCardAlertOpen: false }); Basket.setPMTSingle(true); forwardTo('/card-add', { saveCard: false }) }
              },
            ]}
          />
        </Loading>

        <IonAlert
					isOpen={isDefined(removeCardAlert)}
					onDidDismiss={() => this.handleRemoveCardAlert(null)}
					header={__('Confirm')}
					message={__('Do you you want to remove this card?')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('Remove'),
							handler: () => this.removePaymentCard(),
						},
					]}
				/>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
    deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
    restaurants: store.restaurants.restaurants || [],
    token: store.orders.token || '',
    cards: store.orders.cards || [],
    orderHistory: store.orders.orderHistory || []

  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
