import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonInput, IonAlert } from '@ionic/react';
import { withRouter } from 'react-router';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import './index.css';
import TermsModal from '../../components/termsModal';
import { sendFeedback, setCommonModal } from '../../store/actions';

import talkGif from '../../assets/images/talk.gif';

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: false,
      comment: '',
    };
    this.test = false;
  }

  closeModalAndClearComment(){
    this.props.dispatch(setCommonModal('isFeedbackModalOpen', false));
    this.setState({ comment: ""});
  }

  render() {
    const { __, isFeedbackModalOpen } = this.props;
    return (
      <Loading transparent>
        <Layout
          color="transparent"
          showHeader
          showFooter
          hideSecondToolbar={true}
          headerTitle={__('Feedback')}
          scrollY={false}
        >
          <div className="absolute-content scrollable-y">
            <img src={talkGif} />
            <NormalText className="contact-subtitle">{__('Contact us')}</NormalText>
            <NormalText className="uppercase blocked-input">
              {__('Do you have a question about our app?')}
            </NormalText>
            <NormalText>{__('*')}</NormalText>
            <Spacer size={1} />
            <div className="comment-wrapper">
              <IonItem lines="none" className="">
                <IonInput
                  className=""
                  placeholder={__('Your Comments...')}
                  type="text"
                  pattern="text"
                  inputmode="text"
                  onIonInput={(e) => this.setState({ comment: e.target.value })}
                ></IonInput>
              </IonItem>
              <Spacer size={1} />
              <IonButton
                expand="block"
                color="secondary"
                strong={true}
                disabled={!(this.state.comment || "").replaceAll(" ","")}
                onClick={() => {
                  const feedback = {
                    food: null,
                    service: null,
                    commentService: this.state.comment,
                    commentTech: null,
                    selectedRestaurant: null,
                    techSupport: null,
                    customerService: null,
                  };
                  this.props.dispatch(sendFeedback(feedback));
                }}
              >
                {__('Submit')}
              </IonButton>
            </div>
            <Spacer size={1} />
            <div className="centered">
              <NormalText>{`*${__('By clicking submit, I agree with the')} `}</NormalText>
              <NormalText
                onClick={() => this.setState({ showTerms: true })}
                className="pointer link-color-secondary underlined"
              >
                {`${__('terms and conditions and privacy policy')}`}
              </NormalText>
            </div>
          </div>
        </Layout>
        <TermsModal
          isOpen={this.state.showTerms}
          onDidDismiss={() => this.setState({ showTerms: false })}
        />
        <IonAlert
          isOpen={isFeedbackModalOpen ? true : false}
          onDidDismiss={() => this.closeModalAndClearComment()}
          header={isFeedbackModalOpen.status || __('Warning')}
          message={__(isFeedbackModalOpen.message) || __('feedback fails.')}
          cssClass="no-table-alert one-red-button-alert"
          buttons={[
            {
              text: __('OK'),
              handler: () => this.closeModalAndClearComment(),
              cssClass: 'two-button-secondary',
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { isFeedbackModalOpen } = store.common;
  return {
    isFeedbackModalOpen
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Feedback)));
