import React from 'react';
import {
    IonItem,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonIcon,
    IonGrid,
    IonButton,
    IonAlert,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import {
    SmallText,
    Spacer,
    Subtitle,
    StrongText,
} from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import {

    forwardTo,
    goBack,
    isDefined,
    isWebConfig

} from '../../lib/utils';
import { cardOutline, removeCircleOutline } from 'ionicons/icons';
import { GET_ORDER_HISTORY, GLOBALPAY_ORDER_PAYMENT, SET_COMMON_PROP, SET_PROFILE_PROP } from '../../store/constants';
import { getProfile, loading, removePaymentCard, setAccessToken, setCardToken, setUserCards, showToast } from '../../store/actions';
import api from '../../lib/api';
import { getEnv } from '../../lib/env';
import asyncStorage from '../../lib/asyncStorage';
import { getConfig } from '../../appConfig';
const { getMobile, setMobile } = Basket;

class NativeCheckout extends React.Component {
    constructor(props) {
        super(props);
        if (!getMobile() && this.props.profile && this.props.profile.mobile) {
            setMobile(this.props.profile.mobile);
        }
        const defaultCard =
            this.props.cards.length > 0 &&
            this.props.profile.cardToken &&
            this.props.cards.find((card) => card.id === this.props.profile.cardToken);
        this.state = {
            collectedModal: false,
            collectedPay: this.props.cards.length === 0,
            showAllCards: false,
            pickTime: null,
            removeCardAlert: null,
            cards: [],
            saveCardAlertOpen: false
        };
    }

    removePaymentCard = () => {
        const { __ } = this.props;
        this.props.dispatch(
            removePaymentCard({ token: this.state.card_token, pmt: this.state.removeCardAlert }, {
                __,
                cb: () => this.setState({ removeCardAlert: null }),
            }),
        );
    };
    nativePayment = async () => {
        try {
            const websiteUrl = getConfig()?.general?.websiteUrl || "https://fmapp.francomanca.co.uk";
            this.props.dispatch(loading(true))
            const {
                checkVersion,
                getBrowserData,
                initiateAuthentication,
                AuthenticationSource,
                AuthenticationRequestType,
                MessageCategory,
                ChallengeRequestIndicator,
                ChallengeWindowSize,
            } = GlobalPayments.ThreeDSecure;
            const versionCheckData = await checkVersion(`${getEnv('BASE_URL')}/order/check_version_3ds`, {
                challengeNotificationUrl: `${websiteUrl}/native-payment-result-view`,
                threeDsMethodReturnUrl: `${websiteUrl}/native-payment-result-view`,
                token: this.state.token,
                pmt: this.state.selectedCard,
                amount: this.state.orderData.order_value,
                email: this.state.email,
                card: {
                    reference: this.state.selectedCard
                },
                custom: {
                    id: this.state.orderData.id
                },
                reference: `${this.state.email.replace(/['`{}=?^!#$%&*@+,|]/g, '_')}`
            });
            Basket.setGlobalpayAut(versionCheckData.serverTransactionId)
            Basket.setGlobalpayToken(this.state.token)
            Basket.setGlobalpayCardToken(this.state.card_token)
            if (versionCheckData.status === "NOT_ENROLLED" && versionCheckData.liabilityShift !== "YES") {
                this.props.dispatch(showToast(this.props.__('Your payment has failed'), 'warning'));
                this.props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: true,
                });

                return false;
            }

            if (versionCheckData.liabilityShift === "YES") {
                return true;
            }
            if (versionCheckData.response_data?.error_code || versionCheckData.error_code || versionCheckData.error) {
                this.props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: true,
                });
            }

            const authenticateData = await initiateAuthentication(`${getEnv('BASE_URL')}/order/initiate_authentication_3ds`, {
                challengeNotificationUrl: `${websiteUrl}/native-payment-result-view`,
                challengeWindow: {
                    windowSize: ChallengeWindowSize.Windowed600x400,
                    displayMode: 'lightbox',
                },
                methodUrlComplete: true,
                card: {
                    reference: this.state.selectedCard
                },
                custom: {
                    merchantContactUrl: `${websiteUrl}/native-payment-result-view`,
                    amount: this.state.orderData.order_value,
                    id: this.state.orderData.id
                },
                authenticationRequestType: AuthenticationRequestType.PaymentTransaction,
                serverTransactionId: versionCheckData.serverTransactionId,
                token: this.state.token
            });
            if (authenticateData.error_code) {
                this.props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: true,
                });
            }
            const isAuthenticated = authenticateData.status === "SUCCESS_AUTHENTICATED"
            if (isAuthenticated) {
                const data = {
                    id: this.state.orderData.id,
                    pmt: this.state.selectedCard,
                    pmt_selected: true,
                    pmt_single: Basket.getPMTSingle(),
                    aut: versionCheckData.serverTransactionId,
                    token: this.state.token,
                    card_token:this.state.card_token
                }
                this.props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: false,
                });

                this.props.dispatch({ type: GLOBALPAY_ORDER_PAYMENT, data })

                this.props.dispatch(loading(false))
                forwardTo('/native-order-completed', { completedOrder: true });

            } else {
                forwardTo('/native-order-completed', { completedOrder: true });
            }
        } catch (err) {
            this.props.dispatch({
                type: SET_COMMON_PROP,
                key: `paymentProcessCompleted`,
                value: true,
            });
            if (err.message) {
                this.props.dispatch(showToast(err.message, 'warning', 'Payment failed'));
            } else {
                this.props.dispatch(showToast(this.props.__('Your payment has failed'), 'warning'));
            }
            forwardTo('/native-order-completed', { completedOrder: true });

        }
    }
    async componentDidMount() {

        var _props = this.props
        var _body = document.body

        window.addEventListener('message', function (event) {
            if (event.data == 'GLOBALPAY_WINDOW_CLOSED') {
                _props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
                _props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: true,
                });
                _body.removeChild(document.querySelector('[id^=GlobalPayments-overlay]'))
                forwardTo('/native-order-completed')
            }
            if (event.data == 'GLOBALPAY_WINDOW_CLOSED_FAILED') {

                _props.dispatch({
                    type: SET_COMMON_PROP,
                    key: `paymentProcessCompleted`,
                    value: true,
                });
                _props.dispatch(loading(false))
                _body.removeChild(document.querySelector('[id^=GlobalPayments-overlay]'))
                forwardTo('/native-order-completed')

            }

        });

        try {
            this.props.dispatch(loading(true))

            const urlData = new URLSearchParams(this.props.location.search)
            const data = JSON.parse(decodeURIComponent(urlData.get('data')))
            await asyncStorage.setItem('token', data.token);
            this.props.dispatch({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token: data.token } })
            await api.createAxiosInstance(data.token)
            await this.props.dispatch(getProfile())
            setTimeout(async () => {
                const order = await api.getOrderById({ id: data.order_id })
                const paymentData = await api.getAccessToken()
                this.setState({
                    token: paymentData.data.token,
                    cards: paymentData.data.payment_methods,
                    orderData: order.data.order,
                    email: data.email,
                    card_token: paymentData.data.card_token
                })
                this.props.dispatch(setAccessToken(paymentData.data.token))
                this.props.dispatch(setCardToken(paymentData.data.card_token))
                this.props.dispatch(setUserCards(paymentData.data.payment_methods))
                this.props.dispatch(loading(false))
            }, 1000);

        }
        catch (err) {
            console.log({ err })
        }

    }
    componentDidUpdate(prevProps) {
        const { profile } = this.props;
        if (this.props.cards.length !== prevProps.cards.length) {
            this.setState({ cards: this.props.cards })
        }
    }
    changeSelectedPaymentCard = (cardId) => {
        this.setState({ selectedCard: cardId })
        Basket.setPMT(cardId)
        Basket.setPMTSelection(true)
        const selectedCard = this.state.cards.filter(el => el.id == cardId)
        Basket.setSelectedCard({
            last_four: selectedCard[0].card.number_last4.substr(-4),
            brand: selectedCard[0].card.brand,
            location:'NATIVE CHECKOUT',
            card_json:JSON.stringify(selectedCard[0])
        })
    };
    componentWillUnmount() {
        this.props.dispatch(loading(false))
    }
    handleOrderCreation = () => {
        try {
            Basket.createOrder()
            return
        }
        catch (err) {
            this.props.dispatch(showToast(this.props.__('Payment failed'), 'warning'));
            this.props.dispatch({
                type: SET_COMMON_PROP,
                key: `paymentProcessCompleted`,
                value: true,
            });
        }
    }
    drawSubItemsText = (basketItem = {} /*, basketItemIndex*/) => {
        const { selectedChoices } = basketItem;
        const basketInstance = this.props.basketInstance || Basket;
        const { profile } = this.props;
        if (selectedChoices && selectedChoices.length > 0) {
            return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
                return (choiceGroup || []).map((choice, choiceIndex) => {
                    const { sku } = choice;
                    return (
                        <div className="flex-justify-between flex-col-wrapper">
                            <SmallText className='uppercase dark-color'>+ {Basket.getProductName(choice, profile)}
                                {parseInt(choice.productPrice) > 0 &&
                                    <span className='subchoice-price secondary-color'>+{Basket.formatPrice(choice.productPrice)}</span>
                                }
                            </SmallText>


                        </div>

                    );
                });
            });
        }
        return null;
    };
    handleRemoveCardAlert = (cardId) => {
        this.setState({ removeCardAlert: cardId })
    };
    render() {
        const {
            __,
            restaurants,
            profile
        } = this.props;
        const { orderData, saveCardAlertOpen, removeCardAlert } = this.state

        return (
            <>
                <Loading >
                    <Layout
                        showHeader
                        disableHeader
                        headerWithTitle={true}
                        hideSecondToolbar={true}
                        color="transparent"
                        headerTitle={__('Checkout')}
                        backHandler={goBack}
                    >

                        <div className='absolute-content scrollable-y'>
                            <IonGrid>
                                <Subtitle className="mb-15 block secondary-font dark-color">
                                    {__('Select payment method')}
                                </Subtitle>
                            </IonGrid>
                            <Spacer size={1} />

                            <IonRadioGroup onIonInput={(e) => this.changeSelectedPaymentCard(e.detail.value)} value={this.state.selectedCard}>
                                {this.state?.cards.map((card) => {
                                    const { number_last4, brand, expiry_month, expiry_year } = card.card;
                                    const { id } = card
                                    return (
                                        <IonItem lines="none" className="box-content checkout-card " key={id}>
                                            <IonIcon slot="start" icon={cardOutline}></IonIcon>

                                            <IonLabel className="ion-text-wrap">
                                                <StrongText className="dark-color block">**** **** **** {number_last4.substr(-4)}</StrongText>
                                                <SmallText className='secondary-font'>
                                                    {__(brand)} - {expiry_month}/{expiry_year}
                                                </SmallText>
                                            </IonLabel>
                                            <IonRadio
                                                color={isWebConfig() ? 'secondary' : 'white'}
                                                slot="start"
                                                value={id}
                                                onIonSelect={() => {
                                                    this.setState({ selectedCard: id }, () => {
                                                    });
                                                }}
                                            />
                                            {!getConfig().flags.removeCardHidden &&
                                                <IonButton onClick={() => this.handleRemoveCardAlert(id)} className="link">
                                                    <IonIcon icon={removeCircleOutline} />
                                                </IonButton>
                                            }
                                        </IonItem>
                                    )
                                })}
                            </IonRadioGroup>

                            <IonButton
                                onClick={() => this.setState({ saveCardAlertOpen: true })}
                                class="link underlined"
                                expand="block"
                            >{this.state?.cards.length > 0 ? __('Add another card') : __('Add card')}</IonButton>
                            {this.state?.cards?.length > 0 &&
                                <IonButton disabled={!this.state.selectedCard} onClick={() => this.nativePayment()} color='secondary' expand='block'>{__('Pay')}</IonButton>

                            }
                        </div>
                        <IonAlert
                            isOpen={saveCardAlertOpen}
                            onDidDismiss={() => this.setState({ saveCardAlertOpen: false })}
                            header={__('Confirm')}
                            cssClass="two-button-alert"

                            message={__('Do you you want to save this card for future use?')}
                            buttons={[
                                {
                                    text: __('Yes'),
                                    cssClass: 'secondary',
                                    handler: () => { this.setState({ saveCardAlertOpen: false }); Basket.setPMTSingle(false); forwardTo('/native-card-add', { saveCard: true, orderData: this.state.orderData }) }
                                },
                                {
                                    text: __('No'),
                                    cssClass: 'two-button-gray',
                                    handler: () => { this.setState({ saveCardAlertOpen: false }); Basket.setPMTSingle(true); forwardTo('/native-card-add', { saveCard: false, orderData: this.state.orderData }) }
                                },
                            ]}
                        />

                    </Layout>
                    <IonAlert
                        isOpen={isDefined(removeCardAlert)}
                        onDidDismiss={() => this.handleRemoveCardAlert(null)}
                        header={__('Confirm')}
                        message={__('Do you you want to remove this card?')}
                        buttons={[
                            {
                                text: __('Cancel'),
                                role: 'cancel',
                                cssClass: 'secondary',
                            },
                            {
                                text: __('Remove'),
                                handler: () => this.removePaymentCard(),
                            },
                        ]}
                    />
                </Loading>
            </>
        );
    }
}

const mapStateToProps = (store) => {
    const { auth } = store.profile;

    return {
        clientSecret: store.orders.clientSecret,
        profile: store.profile.profile || {},
        deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
        restaurants: store.restaurants.restaurants || [],
        token: store.orders.token || '',
        cards: store.orders.cards || [],
        orderHistory: store.orders.orderHistory || [],
        auth

    };
};

export default connect(mapStateToProps)(withTranslation(NativeCheckout));
