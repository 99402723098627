import React, { createRef, useState } from 'react';
import { IonInput } from '@ionic/react';
import './index.css';

const SplitedInput = ({
  format = 'IIII-IIII',
  valueHandler,
  text,
  inputClass = 'splited-input',
  predefinedCode,
  inputProps,
}) => {
  let [smsCode, setSmsCode] = useState(false);
  const numberOfInputs = format.replace(/[^a-z]/gi, '').length;
  const inputsRefs = React.useRef([]);
  if (inputsRefs.current.length !== numberOfInputs) {
    inputsRefs.current = Array(numberOfInputs)
      .fill()
      .map((_, i) => inputsRefs.current[i] || createRef());
  }

  const onKeyDown = (keyOriginal, index, val) => {
    const nextIndex = index - 1;
    let key = keyOriginal
    if (key == 'Unidentified') {
      key = val
    }
    if (
      key === 'Backspace' &&
      inputsRefs.current.length > nextIndex &&
      inputsRefs.current[nextIndex] &&
      inputsRefs.current[nextIndex].current &&
      !inputsRefs.current[index].current.value
    ) {
      if (text.length === numberOfInputs) {
        text = text.slice(0, -2);
      } else {
        text = text.slice(0, -1);
      }
      valueHandler(text);
      inputsRefs.current[nextIndex].current.setFocus();
    } else if (key.length === 1 && text.length !== numberOfInputs) {
      text = text + key;
      valueHandler(text);
    }
    setSmsCode(null);
  };

  const onChange = (e, index) => {
    if (e.target.value) {
      const nextIndex = index + 1;
      if (
        inputsRefs.current.length > nextIndex &&
        inputsRefs.current[nextIndex] &&
        inputsRefs.current[nextIndex].current
      ) {
        inputsRefs.current[nextIndex].current.setFocus();
      }
    }
  };

  const createInputs = (numberOfInputs, code = '', inputProps) => {
    let inputs = [];
    let predefinedCode = null;
    if (code.length > 0) {
      predefinedCode = code.split('');
    }
    for (let i = 0; i < numberOfInputs; i++) {
      inputs.push(
        <IonInput
          autocomplete="one-time-code"
          className={`splited-input uppercase ${inputClass}-${i}`}
          key={i}
          ref={inputsRefs.current[i]}
          autofocus={i === 0 && true}
          maxlength={1}
          clear-on-edit={true}
          onKeyDown={(e) => {
            if (!/[^/*-+.]/g.test(e.key)) {
              e.preventDefault();
            } else if (!e.target.value) {
              setTimeout(() => {
                return onKeyDown(e.key, i, e.target.value);
              }, 100);
            }
            return false;
          }}
          {...(predefinedCode && { value: predefinedCode[i] })}
          onIonInput={(e) => {
            if (e.target.value && e.target.value.length > 1) {
              e.target.value = e.target.value.substring(0, 1);
            }
            setTimeout(() => {
              return onChange(e, i);
            }, 50);
          }}
          onBeforeInput={(e) => {
            if (e.nativeEvent.data.length === numberOfInputs) {
              valueHandler(e.nativeEvent.data);
              setSmsCode(e.nativeEvent.data);
            }
          }}
          {...inputProps}
        />,
      );
    }
    for (let i = 0; i < format.length; i++) {
      if (format[i] !== 'I') {
        const spliterChar = (
          <div className="spliter-char" key={`spliter-char-${i}`}>
            {format[i]}
          </div>
        );
        inputs.splice(i, 0, spliterChar);
      }
    }
    return inputs;
  };

  return (
    <div className="splited-input-wrapper">
      {createInputs(numberOfInputs, smsCode ? smsCode : predefinedCode, inputProps)}
    </div>
  );
};

export default SplitedInput;
