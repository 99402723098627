import React, { useState, useEffect, useRef } from 'react';
import {
  IonSegment,
  IonContent,
  IonSegmentButton,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import './index.css';
import { isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import redeemIcon from '../../assets/images/reedem-point-icon.svg';
import { StrongText, Subtitle } from '../common';
import Slider, { Slide } from '../slider';

const SwipableTabs = ({ defaultTab = 0, available_balance, __, displayFooter, displayFooterTab, tabs = [], history, goToFirstSegment }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [swiper, setSwiper] = useState(null);
  const slidesRef = useRef(null);
  const segmentRef = useRef(null);

  useEffect(() => {
    if (history?.state?.tab === 'order' ) {
      segmentChanged(1);
    }
  }, [history]);

  useEffect(() => {
    if(goToFirstSegment){
      segmentChanged(0);
    }
  }, [goToFirstSegment]);

  useEffect(() => {
    if (history?.state?.tab === 'scan') {
      segmentChanged(1);
    } else if (history?.state?.tab === 'points') {
      segmentChanged(0);
    }
  }, [history?.state]);

  useEffect(() => {
    if (swiper && history?.state?.tab === 'order') {
      segmentChanged(1);
    }
  }, [swiper, history?.state]);

  const segmentChanged = (value) => {
    setSelectedTab(value);
    
    if (swiper) {
      swiper.slideTo(value);
    }
  };

  const onSegmentChange = (ev) => {
    slideTo(ev.detail.value);
  };

  const slideTo = (index) => {
    slidesRef.current.slideTo(index);
  };

  const onSlideDidChange = async () => {
    const index = await slidesRef.current.getActiveIndex();
    clickSegment(index);
  };

  const clickSegment = (index) => {
    segmentRef.current.value = index;
  };

  return (
    <div className="tabs">
      <div className="segment-holder">
        <IonSegment
          onIonChange={(e) => segmentChanged(e.detail.value)}
          value={selectedTab}
          mode="md"
          innerRef={segmentRef}
        >
          {tabs.map((tab, index) => {
            const { label, icon } = tab;
            return (
              <IonSegmentButton key={index + '_segment'} value={index}>
                {isDefined(icon) ? <IonIcon name={icon}></IonIcon> : null}
                <IonLabel>{label || ''}</IonLabel>
              </IonSegmentButton>
            );
          })}
        </IonSegment>
      </div>
      <Slider
        settings={{
          loop: false,
          initialSlide: selectedTab,
          onSlideChange: (swiper) => segmentChanged(swiper.activeIndex),
          onSwiper: setSwiper,
        }}
        innerRef={slidesRef}
      >
        {tabs.map((tab, index) => (
          <Slide key={index + 'slide'} className={'slide-' + (index + 1)}>
            <IonContent>{tab.tabContent || null}</IonContent>
          </Slide>
        ))}
      </Slider>
      {displayFooter === 'balance' && isDefined(displayFooterTab) && displayFooterTab === selectedTab && (
        <div className="loyalty-bar">
          <div>
            <div className="flex-col-wrapper">
              <img src={redeemIcon} alt=' ' />
            </div>
          </div>
          <div>
            <Subtitle className='roboto-slab'>{__('Loyalty Balance')}</Subtitle>
          </div>
          <div>
            <StrongText color="primary" className=" loyalty-balance thiner-text roboto-slab">
              {available_balance} {__('Points')}
            </StrongText>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation(SwipableTabs);
