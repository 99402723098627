import React, { Component } from 'react';
import { IonButton, IonRefresher, IonRefresherContent } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import { NormalText, Spacer, Subtitle } from '../../components/common';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getBalance,
  getMycheckUrl,
  getMyTable,
  getProfile,
  getVouchers,
} from '../../store/actions';
import logoGif from '../../assets/images/backgrounds/welcome-box-pic.png';
import Loading from '../../components/spinner';
import Slider, { Slide } from '../../components/slider';
import { getConfig } from '../../appConfig';
import Basket from '../../lib/basket';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderContent: [],
      connectionIsBack: false,
    };
  }
  openExternalLink = (url) => window.open(url);

  componentDidMount() {
    this.props.dispatch(getBalance());
    this.props.dispatch(getMyTable(true));

    this.setState({
      sliderContent: [
        'COLLECT 6 STAMPS FOR A PIZZA ON THE HOUSE ',

        'INVITE YOUR FRIENDS AND RECEIVE EXTRA STAMPS!',
      ],
    });
  }

  doRefresh = (event) => {
    setTimeout(() => {
      event.detail.complete();
      this.props.dispatch(getProfile());
      this.props.dispatch(getBalance());
      this.props.dispatch(getVouchers());
    }, 1000);
  };

  render() {
    const { __, balance, isConnectedToNetwork, profile } = this.props;
    const { connectionIsBack } = this.state;
    let hasOrdering = false
    if ((getConfig().orderTesting && getConfig().orderTesting.allowedUsers.includes(profile.ctcid)) || !getConfig().orderTesting) {
      hasOrdering = true
    }
    return (
      <Loading transparent>
        <Layout showHeader showFooter color="transparent" >
          <IonRefresher slot="fixed" onIonRefresh={(e) => this.doRefresh(e)}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <div
            className={`absolute-content scrollable-y ${!isConnectedToNetwork ? 'no-top-padding' : ''
              }`}
          >
            {!isConnectedToNetwork && (
              <IonButton className="uppercase you-are-offline-banner" color={'secondary'}>
                {__('oops! you are offline.')}
              </IonButton>
            )}
            {isConnectedToNetwork && connectionIsBack && (
              <IonButton className="uppercase" color={'secondary'}>
                {__('you are online.')}
              </IonButton>
            )}

            <Slider
              className="intro-slider"
              settings={{
                navigation: true,
                pagination: true
              }}
            >
              {this.state.sliderContent.map((el, index) => (
                <Slide key={index}>
                  <div className="homepage-banner-swipper centered">
                    <img src={logoGif} />
                    <NormalText>{__(el)}</NormalText>
                  </div>
                </Slide>
              ))}
            </Slider>
            <div className="rewards-wrapper">
              {new Array(6).fill(true).map((el, index) => {
                return <div className={`${index < balance ? 'checked' : ''}`}></div>;
              })}
            </div>

            <Spacer size={1} />
            <div className="homepage-actions">
              {isEmptyObject(this.props.tableData) ? (
                <IonButton expand="block" color="secondary" onClick={() => forwardTo('/scan')}>
                  {__('Table check in')}
                </IonButton>
              ) : (
                <IonButton expand="block" color="secondary" onClick={() => forwardTo('/table')}>
                  {__('View your table')}
                </IonButton>
              )}

              <IonButton color="secondary" onClick={() => forwardTo('/add-stamps')}>
                <span className="btn-text">
                  {__('Add')}
                  <br /> {__('stamp')}
                </span>
              </IonButton>
              <div className="rewards-btn-wrapper">
                {this.props.vouchers.filter((voucher) => !voucher.Redeemed).length > 0 && (
                  <p
                    className={`rewards-btn-indicator ${this.props.vouchers.length > 9 ? this.props.vouchers.length > 99 ? 'rewards-btn-three-digits' : 'rewards-btn-two-digits' : '' }`}
                  >
                    {this.props.vouchers.filter((voucher) => !voucher.Redeemed).length}
                  </p>
                )}
                <IonButton
                  disabled={this.props.vouchers.length == 0}
                  color="secondary"
                  onClick={() => forwardTo('/history', { tab: 'rewards' })}
                >
                  <span className="btn-text">
                    {__('View')}
                    <br /> {__('rewards')}
                  </span>
                </IonButton>
              </div>
            </div>
            <Spacer size={1} />
            <div className="panels-wrapper">
              {this.props.profile?.news?.map((item) => (
                <>
                  <div
                    onClick={() => {
                      if (item.url.includes('https://order.francomanca.co.uk')) {
                        if (hasOrdering) {
                          if (Basket.getItems().length > 0) {
                            this.setState({ emptyBasketAlertOpen: true })
                          } else {
                            forwardTo('/click-and-collect')

                          }
                        }
                        else {
                          this.props.dispatch(getMycheckUrl())
                      }
                        
                      } else {
                    this.openExternalLink(item.url);
                      }
                    }}
                  className={`panel-wrapper panel-type-${item.template_number}`}
                  >
                  <div>
                    <Subtitle
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />
                    <div
                      className="content-panel-text"
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    ></div>
                  </div>
                  <img src={item.banner_image} />
                </div >
              <Spacer size={1} />
                </>
              ))}
          </div>
        </div>
      </Layout>
      </Loading >
    );
  }
}

const stateToProps = (state) => {
  const { profile, balance, vouchers } = state.profile;
  const { tableData } = state.orders;
  const { isConnectedToNetwork } = state.common;
  return {
    profile,
    balance,
    vouchers,
    tableData,
    isConnectedToNetwork,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Homepage)));
