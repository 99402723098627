import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanType, Html5Qrcode } from 'html5-qrcode';
import { forwardTo } from '../../lib/utils';
import { IonButton } from '@ionic/react';
import { Spacer } from '../common';

const QrCodeScanner = ({ onScanSuccess, onScanFailure, __ }) => {
  const scannerRef = useRef(null);
  let html5QrcodeScanner = null;

  const startCamera = async () => {
    let cameraId = 0;
    try {
      const cameras = await Html5Qrcode.getCameras();
      const backCamera = cameras.find(camera => camera.label.toLowerCase().includes('back'));
      cameraId = backCamera?.id || cameras[0]?.id;
    } catch (error) {
      forwardTo('/check-table', { disableAutoFocus: true });
      return null;
    }
    
    try {
      const cameraConfig = {
        fps: 5,
        qrbox: 250,
        rememberLastUsedCamera: true,
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      };
      html5QrcodeScanner = new Html5Qrcode('qr-code-scanner', false);
      html5QrcodeScanner.start(cameraId, cameraConfig, onScan, onScanFailure);      
    } catch (error) {
      console.log("Error starting camera!", error);
      await stopCamera();
    }
  }

  const stopCamera = async () => {
    if(html5QrcodeScanner){
      await html5QrcodeScanner.stop();
      html5QrcodeScanner.clear();
    }
  }

  const onScan = async (value) => {
    await stopCamera();
    if(onScanSuccess){
      await onScanSuccess(value);
    }
  }

  useEffect(() => {
    if (scannerRef.current) {
      startCamera();
    }
  }, []);

 
  return (
    <>
      <div id="qr-code-scanner" ref={scannerRef}></div>
      <Spacer />
      <IonButton
        color="secondary"
        onClick={async () => {
          await stopCamera();
          forwardTo('/check-table', { disableAutoFocus: true });
        }}>
        {__ ('Cancel')}
      </IonButton>
    </>

  );
};

export default QrCodeScanner;
